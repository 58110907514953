import React from "react";
import HeroBanner from "../Sections/HeroBanner";
import { useState } from "react";
import { MdChevronRight } from "react-icons/md";
import SectionHeading from "../Component/SectionHeading";
import { IoChevronForward } from "react-icons/io5";

const navigationTitle = [
  "Day Tours & Multi-Day Tours",
  "Transfers",
  "Attractions & Cruises",
  "Flight",
  "Accommodation",
  "Credit Card Use & Surcharges",
  "Force Majeure",
  "Indemnity/Limitation of liability",
  "Disputes & Complaints",
  "Governing Law",
];

const Terms = () => {
  const [selectedNavigation, setSelectedNavigation] = useState(
    navigationTitle[0]
  );
  const handleNavigation = (x) => {
    setSelectedNavigation(x);
  };
  return (
    <>
      <HeroBanner
        text={"Terms & Condition"}
        imgUrl={"/assets/contact/hero-banner.png"}
      />
      <div className="container mx-auto">
        <div className="mx-3 lg:flex gap-6 py-12">
          <div className="lg:w-[320px] border h-max py-6 mb-6 lg:mb-0">
            <h1 className="font-semibold text-xl px-4 pb-3">Navigation</h1>
            {navigationTitle.map((x, index) => (
              <button
                onClick={() => handleNavigation(x)}
                key={index}
                className={`relative flex items-center font-medium gap-1 py-3 mx-3 ${
                  selectedNavigation === x ? "text-primary" : ""
                }`}
              >
                <span
                  className={`h-full w-1 absolute -left-3 ${
                    selectedNavigation === x ? " bg-primary" : ""
                  }`}
                ></span>
                <MdChevronRight />
                {x}
              </button>
            ))}
          </div>
          <div className="lg:w-[calc(100%-344px)]">
            {/* day tours */}
            {selectedNavigation === "Day Tours & Multi-Day Tours" && (
              <>
                <SectionHeading text={"Day Tours & Multi-Day Tours"} />
                <p className="mt-6">
                  <strong>FLY MILES PTY. LTD.</strong> also known as Fly Miles
                  and its associated Operators and Contractors will be carrying
                  this service on behalf of Fly Miles. FLY MILES as an Operator
                  and its Contractor are not responsible for any services, costs
                  or losses including missed attraction tickets or anything
                  else, if customer is late, abusive or marked as No-Show,
                  delay’s or booking errors. Fly Miles will not make any
                  compensation on behalf of any Tours. It is the hirer’s
                  responsibility to read the full TERMS & CONDITIONS and advise
                  of any changes as full costs may be incurred.
                  <br />
                  <br />
                  As once the booking is made or confirmed that means hirer's is
                  accepting all TERMS & CONDITIONS without exception.
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Booking Policy</h2>
                </div>
                <p className="mt-4">
                  www.flymiles.com.au is a website owned and operated by FLY
                  MILES PTY. LTD. our website is an online booking platform that
                  allows users to book tours available within us or our network
                  across destinations we serve. It is responsibility of the
                  person who book the tour to ensure that all the details
                  entered when you make a booking are accurate. All prices are
                  subject to availability and can be withdrawn or varied without
                  notice. The price is only guaranteed once your booking has
                  been paid for in full by you.
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Change or Cancellation Policy</h2>
                </div>
                <p className="my-4">
                  Any changes/amendments to your booking after confirmation can
                  only be accepted subject to availability. FLY MILES reserves
                  the right to charge an amendment fee in respect of any change
                  to your booking, in addition to any other applicable charges
                  in relation to the amendment.
                </p>
                <ul className="list-disc">
                  <li className="text-sm mb-1 ms-4">
                    Cancellations can be made directly on our website by
                    visiting Manage Booking and search for your booking and
                    click "Cancel my Booking".
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Change or Cancellation made with less than cancellation
                    period specified on booking will incur charges.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    A 100% cancellation fee applies for tours cancelled within
                    48 hours of departure when no notice is given. However, if
                    notice of cancellation is given and the tour is re-booked
                    there is no charge.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Please make sure you receive email from us to confirm any
                    Change or Cancellation. The time of cancellation is
                    considered to be the time we confirm your cancellation
                    request.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    For all Sydney/Gold Coast tours; a cancellation fee of 50%
                    applies for a tour cancelled within 72-48 hours of travel or
                    when no notice is given. Within 48 hours of departure 100%
                    cancellation fee applies. Cancellation fees imposed on FLY
                    MILES PTY. LTD. by our supplier will be passed on.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    No-Show booking will be charged in full; you will not be
                    entitled to a refund.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    For Private Touring in Sydney/Gold Coast; a cancellation fee
                    of 100% applies for a tour cancelled within 72 hours of
                    travel or when no-show.
                  </li>
                </ul>
                <div className="flex font-semibold items-center gap-1 my-4">
                  <IoChevronForward />
                  <h2>Courtesy Hotel Pick Ups</h2>
                </div>
                <ul className="list-disc">
                  <li className="text-sm mb-1 ms-4">
                    Pick up times may vary depending on traffic.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Please wait outside hotel 5 minutes prior to pick up time.
                    If you unable to locate driver please call +61 413 092 724.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Please advise the hotel name when making booking or by
                    sending us email at tours@flymiles.com.au to avail a
                    complimentary Hotel pickup in CBD area.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    If your hotel is not shown in our service area, extra
                    charges will apply.
                  </li>
                </ul>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Refunds</h2>
                </div>
                <p className="my-4">
                  You are entitled to receive a 100% refund on your booking with
                  no cancellations fee, so long as the cancellation is made
                  before the cancellation deadline. <br />
                  <br />
                  All refunds will be processed as quickly as possible; however,
                  it can take up to 15 business days for the funds to appear in
                  your account depending on bank. <br />
                  <br />
                  Please note any additional fee or Credit card surcharge is not
                  refundable.
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Special Request</h2>
                </div>
                <p className="my-4">
                  All special requests are subject to the ability and may incur
                  extra charges. You can book the Special Requests (like: Child
                  Seat, etc.) online through www.flymiles.com.au or by sending
                  us an email to tours@flymiles.com.au <br />
                  <br />
                  Passengers will be liable for all expenses incurred for extra
                  items not declared at the time of booking and driver can
                  charge on spot or may decline to service the booking. In such
                  case no-refunds will be made.
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Waiting Time</h2>
                </div>
                <p className="my-4">
                  Shared Tour coach will maximum wait for below specific times
                  from pickup timing.
                </p>
                <ul className="list-disc">
                  <li className="text-sm mb-1 ms-4">
                    10 minutes – Courtesy Waiting at Hotel Pickup
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    10 minutes – Courtesy Waiting at Home/Address Pickup
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    15 minutes – Courtesy Waiting at Cruise Terminal Pickup
                  </li>
                </ul>
                <p className="my-4">
                  If you book Private Tour – vehicle will maximum wait for 30
                  minutes from pickup timing.
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Insurance</h2>
                </div>
                <p className="my-4">
                  This insurance is for public liability and NOT travel
                  insurance. Although every effort will be made to ensure the
                  safe keeping of personal property, we do not accept liability
                  for loss or damage of personal items. Please check that you
                  have sufficient coverage for any personal property that is
                  brought onto our vehicles.
                </p>
              </>
            )}
            {/* transfesrs conditions */}
            {selectedNavigation === "Transfers" && (
              <>
                <SectionHeading
                  text={"Airport, Cruise Terminal or Point to Point Transfers"}
                />
                <p className="mt-6">
                  <strong>FLY MILES PTY. LTD.</strong> also operate as Fly Miles
                  or Sydney Transfers or its associated Operators and
                  Contractors are not common carriers and may refuse to carry
                  any person/s without giving reason. FLY MILES as a Operator
                  and its Contractor are not responsible for any other services,
                  costs or losses including missed flights or travel
                  connections, resulting from diversion, substitution,
                  alterations, cancellations, delay’s or booking errors. It is
                  the hirer’s responsibility to read the full TERMS & CONDITIONS
                  and advise of any changes as full costs may be incurred.
                  <br />
                  <br />
                  As once the booking is made or confirmed that means hirer's is
                  accepting all TERMS & CONDITIONS without exception.
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Booking Policy</h2>
                </div>
                <p className="mt-4">
                  www.flymiles.com.au is a website owned and operated by FLY
                  MILES PTY. LTD. our website is an online booking platform that
                  allows users to book tours available within us or our network
                  across destinations we serve. It is responsibility of the
                  person who book the tour to ensure that all the details
                  entered when you make a booking are accurate. All prices are
                  subject to availability and can be withdrawn or varied without
                  notice. The price is only guaranteed once your booking has
                  been paid for in full by you.
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Change or Cancellation Policy</h2>
                </div>
                <p className="my-4">
                  Any changes/amendments to your booking after confirmation can
                  only be accepted subject to availability. FLY MILES reserves
                  the right to charge an amendment fee in respect of any change
                  to your booking, in addition to any other applicable charges
                  in relation to the amendment.
                </p>
                <ul className="list-disc">
                  <li className="text-sm mb-1 ms-4">
                    Cancellations can be made directly on our website by
                    visiting Manage Booking and search for your booking and
                    click "Cancel my Booking".
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Change or Cancellation made with less than cancellation
                    period specified on booking will incur charges.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Please make sure you receive email from us to confirm any
                    Change or Cancellation. The time of cancellation is
                    considered to be the time we confirm your cancellation
                    request.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Stretch Limousine or Special vehicle booking cancelled at
                    any time regardless of notice will incur a fee depend on
                    local supplier.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    No-Show booking will be charged in full; you will not be
                    entitled to a refund.
                  </li>
                </ul>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Refunds</h2>
                </div>
                <p className="my-4">
                  You are entitled to receive a 100% refund on your booking with
                  no cancellations fee, so long as the cancellation is made
                  before the cancellation deadline. All refunds will be
                  processed as quickly as possible; however, it can take up to
                  15 business days for the funds to appear in your account
                  depending on bank. Please note any additional fee or Credit
                  card surcharge is not refundable.
                </p>
                <div className="flex font-semibold items-center gap-1 my-4">
                  <IoChevronForward />
                  <h2>Baggage Information</h2>
                </div>
                <p className="my-4">
                  Luggage is limited to one suitcase and one piece of hand
                  luggage per person. Surcharge applies to additional luggage
                  items. Passenger/s should carry valuable and fragile items.
                  FLY MILES PTY. LTD. shall not be liable for the loss of or
                  damage to any personal effects, luggage or other goods,
                  whether or not such loss or damage is suffered, or is caused
                  by anything occurring before, after, or in the course of any
                  journey.
                  <br />
                  <br />
                  Surcharge applies to the follow luggage items.
                </p>
                <ul className="list-disc">
                  <li className="text-sm mb-1 ms-4">
                    Additional suitcases - $10 per extra piece of luggage.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Trailer - $20 extra for trailer (and difference of vehicle
                    change if private transfer).
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Surfboards - $10.00 each. (depend on vehicle booked)
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    Bike in Bike Box - $10 each (and difference of vehicle
                    change if small vehicle booked).
                  </li>
                </ul>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Special Request</h2>
                </div>
                <p className="my-4">
                  All special requests are subject to the ability and may incur
                  extra charges. You can book the Special Requests (like: Child
                  Seat, etc.) online through www.flymiles.com.au or by sending
                  us an email to transfers@flymiles.com.au
                  <br />
                  <br />
                  Passengers will be liable for all expenses incurred for extra
                  items not declared at the time of booking and driver can
                  charge on spot or may decline to service the booking. In such
                  case no-refunds will be made.
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Waiting Time</h2>
                </div>
                <p className="my-4">
                  We monitor all flight using 3rd Party websites and once the
                  plane has been landed we count the industry-standard
                  complimentary waiting time for all bookings:
                </p>
                <ul className="list-disc">
                  <li className="text-sm mb-1 ms-4">
                    60 minutes complimentary waiting time at International
                    Airport Terminals.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    30 minutes complimentary waiting time at Domestic Airport
                    Terminals.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    30 minutes complimentary waiting time at Cruise Terminals.
                  </li>
                  <li className="text-sm mb-1 ms-4">
                    15 minutes complimentary waiting time at Hotel / Home or
                    non-airport/cruise pickup point.
                  </li>
                </ul>
                <p className="my-4">
                  If you book Shared Transfer – vehicle will pickup on schedule
                  time. Maximum 10 minutes wait from pickup timing.
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Insurance</h2>
                </div>
                <p className="my-4">
                  This insurance is for public liability and NOT travel
                  insurance. Although every effort will be made to ensure the
                  safe keeping of personal property, we do not accept liability
                  for loss or damage of personal items. Please check that you
                  have sufficient coverage for any personal property that is
                  brought onto our vehicles.
                </p>
              </>
            )}
            {/* attraction & Cruises */}
            {selectedNavigation === "Attractions & Cruises" && (
              <>
                <SectionHeading text={"Sliders"} />
                <p className="mt-6">
                  Searcys St Pancras' adored Champagne bar is famously the
                  longest in Europe. Here, guests can enjoy panoramic views of
                  the magnificent station. For those looking to enjoy a glass of
                  something special, the menu showcases a variety of Champagne
                  and sparkling wines from around the world, including nine
                  options by the glass. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Maecenas in pulvinar neque.Nulla finibus
                  lobortis pulvinar. Donec a consectetur nulla. Nulla posuere
                  sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                  Aliquam erat volutpat. Curabitur convallis fringilla diam sed
                  aliquam. Sed tempor iaculis massa faucibus feugiat. In
                  fermentum facilisis massa,a consequat purus viverra
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Our Terms</h2>
                </div>
                <p className="mt-4">
                  A wonderful serenity has taken possession of my entire soul,
                  like these sweet mornings of spring which I enjoy with my
                  whole heart. I am alone, and feel the charm of existence in
                  this spot, which was created for the bliss of souls like mine.
                  I am so happy, my dear friend, so absorbed in the exquisite.
                </p>
              </>
            )}
            {/* Flight */}
            {selectedNavigation === "Flight" && (
              <>
                <SectionHeading text={"Terms and Condition"} />
                <p className="mt-6">
                  Searcys St Pancras' adored Champagne bar is famously the
                  longest in Europe. Here, guests can enjoy panoramic views of
                  the magnificent station. For those looking to enjoy a glass of
                  something special, the menu showcases a variety of Champagne
                  and sparkling wines from around the world, including nine
                  options by the glass. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Maecenas in pulvinar neque.Nulla finibus
                  lobortis pulvinar. Donec a consectetur nulla. Nulla posuere
                  sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                  Aliquam erat volutpat. Curabitur convallis fringilla diam sed
                  aliquam. Sed tempor iaculis massa faucibus feugiat. In
                  fermentum facilisis massa,a consequat purus viverra
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Our Terms</h2>
                </div>
                <p className="mt-4">
                  A wonderful serenity has taken possession of my entire soul,
                  like these sweet mornings of spring which I enjoy with my
                  whole heart. I am alone, and feel the charm of existence in
                  this spot, which was created for the bliss of souls like mine.
                  I am so happy, my dear friend, so absorbed in the exquisite.
                </p>
              </>
            )}
            {/* Accommodation */}
            {selectedNavigation === "Accommodation" && (
              <>
                <SectionHeading text={"Address & Map"} />
                <p className="mt-6">
                  Searcys St Pancras' adored Champagne bar is famously the
                  longest in Europe. Here, guests can enjoy panoramic views of
                  the magnificent station. For those looking to enjoy a glass of
                  something special, the menu showcases a variety of Champagne
                  and sparkling wines from around the world, including nine
                  options by the glass. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Maecenas in pulvinar neque.Nulla finibus
                  lobortis pulvinar. Donec a consectetur nulla. Nulla posuere
                  sapien vitae lectus suscipit, et pulvinar nisi tincidunt.
                  Aliquam erat volutpat. Curabitur convallis fringilla diam sed
                  aliquam. Sed tempor iaculis massa faucibus feugiat. In
                  fermentum facilisis massa,a consequat purus viverra
                </p>
                <div className="flex font-semibold items-center gap-1 mt-4">
                  <IoChevronForward />
                  <h2>Our Terms</h2>
                </div>
                <p className="mt-4">
                  A wonderful serenity has taken possession of my entire soul,
                  like these sweet mornings of spring which I enjoy with my
                  whole heart. I am alone, and feel the charm of existence in
                  this spot, which was created for the bliss of souls like mine.
                  I am so happy, my dear friend, so absorbed in the exquisite.
                </p>
              </>
            )}
            {/* Credit Card Use & Surcharges */}
            {selectedNavigation === "Credit Card Use & Surcharges" && (
              <>
                <SectionHeading text={"Credit Card Use & Surcharges"} />
                <p className="mt-6">
                  For all payment made online we use Stripes and Paypal as
                  payment gateway. All credit card surcharge will be in
                  accordance with these merchant policies.
                </p>
                <p className="mt-6">
                  Any credit card payment made over the phone we use
                  Commonwealth Bank Bpoint services which incur credit card
                  surcharge for 1.85% (Visa/Master) and 2% (American Express).
                </p>
              </>
            )}
            {/* Force Majeure */}
            {selectedNavigation === "Force Majeure" && (
              <>
                <SectionHeading text={"Force Majeure"} />
                <p className="mt-6">
                  <strong>FLY MILES PTY. LTD.</strong> and its associated
                  Operators and Contractors are not liable to the other for any
                  default due to any Act of God,War or other hostilities, strike
                  (other than between the affected party and its employees),
                  lock out, natural disaster, fire, Virus Threat, Force Lockdown
                  or other event beyond the reasonable control of either party
                  which is similar in nature. It is your responsibility to
                  update FLY MILES PTY. LTD. and Cancel/Amend booking before it
                  fall in cancellation Period deadline.
                </p>
              </>
            )}
            {/* Indemnity/Limitation of liability */}
            {selectedNavigation === "Indemnity/Limitation of liability" && (
              <>
                <SectionHeading text={"Indemnity/Limitation of liability"} />
                <p className="mt-6">
                  You agree to indemnify FLY MILES PTY. LTD., Australia in
                  relation to any loss or damage suffered by FLY MILES PTY. LTD.
                  in respect of goods or services provided to you in respect of
                  third party arrangements, including but not limited to, claims
                  in relation to damaged equipment.
                  <br />
                  <br />
                  To the extent permitted by law, FLY MILES PTY. LTD. shall not
                  be liable for any injury, loss, accident or delay that may
                  occur due to factors beyond our direct control or by reason of
                  any act or omission of any third party.
                </p>
              </>
            )}
            {/* Disputes & Complaints */}
            {selectedNavigation === "Disputes & Complaints" && (
              <>
                <SectionHeading text={"Disputes & Complaints"} />
                <p className="mt-6">
                  If any disputes or complaint arise, we will check with our
                  driver/transport company/contractors and will reply within 7
                  business days. When you sending us a dispute or complaint
                  please email to disputes@flymiles.com.au and send us as much
                  information you can, so we will verify with our team.
                </p>
              </>
            )}
            {/* Governing Law */}
            {selectedNavigation === "Governing Law" && (
              <>
                <SectionHeading text={"Governing Law and Jurisdiction"} />
                <p className="mt-6">
                  These Terms shall be governed by and construed in accordance
                  with the laws of the New South Wales and the Commonwealth of
                  Australia, without giving effect to principles of conflicts of
                  law.
                  <br />
                  <br />
                  Any legal action, dispute or proceeding arising between you
                  and us in connection with these terms will be subject of the
                  exclusive jurisdiction of the courts located in New South
                  Wales, Australia.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
