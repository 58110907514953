import "./App.css";
import WebsiteLoader from "./Component/Loader";
import Routing from "./Pages/Routing";

function App() {
  return (
    <>
      <WebsiteLoader />
      <Routing />
    </>
  );
}

export default App;
