import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserDetailsForm from "../Component/UserDetailsForm";
import HeroBanner from "../Sections/HeroBanner";
import RequestExtras from "../Component/RequestExtras";
import Payment from "../Component/Payment";
import CouponCodeInput from "../Component/CouponCodeInput";

const TransferBooking = () => {
  const confirmTransferNavigate = useNavigate();
  const location = useLocation();
  const transferData = location.state?.transferData;

  const handleConfirmBooking = (e, transferBookingData) => {
    confirmTransferNavigate(`/transfer-booking-confirm`, {state: {transferBookingData}})
  };


  return (
    <>
      <div className="">
        <HeroBanner
          text={"Transfer"}
          imgUrl={"/assets/contact/hero-banner.png"}
        />
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6 mx-3 py-12">
          <div className="lg:w-[calc(70%-12px)]">
            <UserDetailsForm />
            <RequestExtras />
            <Payment onClick={(e) => handleConfirmBooking(e, transferData)} />
          </div>
          <div className="lg:w-[calc(30%-12px)]">
            <div className="p-3 border rounded">
              <div className="border rounded h-[200px] flex items-center justify-center">
                <img
                  className="h-[150px] flex-none object-contain"
                  src={transferData.imgUrl}
                  alt=""
                />
              </div>
              <h1 className="font-semibold text-center mt-3 text-xl">
                {transferData.vehicleCategory}({transferData.title})
              </h1>
              <CouponCodeInput />
              <div className="mt-6 border-t pt-6">
                <p className="text-xl font-medium mb-3">Booking Details</p>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Route</p>
                  <p>{transferData.route}</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Travel Date</p>
                  <p>{transferData.travelDate}</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Travel Time</p>
                  <p>{transferData.travelTime}</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Distance</p>
                  <p>{transferData.distance}</p>
                </div>
                <div className="flex justify-between gap-3">
                  <p className="font-semibold">Duration</p>
                  <p>{transferData.duration}</p>
                </div>
              </div>
              <div className="mt-6 border-t pt-6">
                <p className="text-xl font-medium mb-3">Extra</p>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Extra Luggage</p>
                  <p className="text-seconday">AU$0.00</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Surfboard</p>
                  <p className="text-seconday">AU$0.00</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Baby Seat</p>
                  <p className="text-seconday">AU$0.00</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Bany Booster</p>
                  <p className="text-seconday">AU$0.00</p>
                </div>
              </div>
              <div className="mt-6 border-t pt-6">
                <p className="text-xl font-medium mb-3">Payment</p>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Subtotal</p>
                  <p className="text-seconday">AU$0.00</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Extra Price</p>
                  <p className="text-seconday">AU$0.00</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Tax(GST)</p>
                  <p className="text-seconday">AU$0.00</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Pay Amount</p>
                  <p className="text-seconday">AU$0.00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferBooking;
