import React from "react";
import { HiCurrencyDollar } from "react-icons/hi2";
import { FaTelegramPlane } from "react-icons/fa";
import RatingStars from "./RatingStars";

const TransferSingleResult = ({
  imgUrl,
  title,
  star,
  vehicleCategory,
  vehicleType,
  vehicleClass,
  inclusion,
  price,
  handlePayLater,
  handlePayNow
}) => {
  return (
    <div className="flex flex-col md:flex-row relative items-center mb-9">
      <div className="w-full md:w-2/5 border flex justify-center items-center xl:w-1/3 h-[250px] rounded-xl">
        <img className="object-cotain w-[70%] object-center" src={imgUrl} alt="img" />
      </div>
      <div className="w-full md:w-[calc(60%+24px)] xl:w-[calc(66.66667%+24px)] border rounded-2xl md:rounded-lg md:-ms-6 -mt-6 md:-mt-0 bg-white px-3 md:px-4 py-5 flex-none">
        <div className="sm:flex justify-between">
          <div>
            <h1 className="text-lg font-semibold mb-1">{title}</h1>
            <div className="flex items-center mb-1">
              <RatingStars star={star} />
              <p className="ms-1 font-medium">({star})</p>
            </div>
            <div className="flex flex-wrap gap-2 mt-4">
                <div className="border border-primary text-primary p-1 rounded font-semibold uppercase">{vehicleType}</div>
                <div className="border border-primary text-primary p-1 rounded font-semibold uppercase">{vehicleClass}</div>
                <div className="border border-primary text-primary p-1 rounded font-semibold uppercase">{vehicleCategory}</div>
                <div className="border border-primary text-primary p-1 rounded font-semibold uppercase">{inclusion}</div>
            </div>
          </div>
          <div className="flex sm:block justify-between">
            <div className="flex items-center gap-1">
              <HiCurrencyDollar color="#63AB45" />
              <p className="font-medium">Price</p>
            </div>
            <p className="text-xl font-bold">US${price}</p>
          </div>
        </div>
        <div className="flex gap-3 items-center justify-end mt-4">
          <button
            onClick={(e)=> handlePayLater(e)}
            className="flex mt-3 xl:mt-0 w-max items-center border rounded-full bg-white text-primary font-medium flex-none"
          >
            <span className="px-4 py-3">Pay Later</span>
            <span className="w-9 h-9 bg-primary text-secondary flex justify-center items-center rounded-full me-1">
              <FaTelegramPlane color={"#fff"} />
            </span>
          </button>
          <button
            onClick={(e) => handlePayNow(e)}
            className="flex mt-3 xl:mt-0 w-max items-center border rounded-full bg-primary text-white font-medium flex-none"
          >
            <span className="px-4 py-3">Pay Now</span>
            <span className="w-9 h-9 bg-white text-secondary flex justify-center items-center rounded-full me-1">
              <FaTelegramPlane color={"#63AB45"} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferSingleResult;
