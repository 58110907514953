import React from "react";
import cn from "classnames";
import Tag from "../Component/Tag";
import SectionHeading from "../Component/SectionHeading";

const whyChooseDetails = [
  {
    icon: "/assets/icons/why-choose-icon-1.png",
    heading: "Australia Wide",
    text: "With 30+ suppliers and capability to handle 2000+ bookings every month, Fly Miles can save you more!",
  },
  {
    icon: "/assets/icons/why-choose-icon-2.png",
    heading: "Best Price Guarantee",
    text: "Price match within 48 hours of order confirmation",
    green: true,
  },
  {
    icon: "/assets/icons/help-desk.png",
    heading: "Top Notch Support",
    text: "We are available 24x7 to help - via Whats App, Chat, Email, Phone.",
  },
];

const WhyChooseUs = () => {
  return (
    <div className="container mx-auto py-12">
      <div className="block lg:flex">
        <div className="w-full lg:w-3/5 p-3">
          <Tag text={"Why Choose Us"} />
          <div className="my-6">
            <SectionHeading
              text={"Great Opportunity For Adventure & Travels"}
            />
          </div>
          <div>
            {whyChooseDetails.map((x, index) => (
              <div className="flex mb-7" key={index}>
                <div className="flex items-center">
                  <img
                    className="w-6 h-6"
                    src="/assets/icons/why-choose-verify.png"
                    alt="verify"
                  />
                  <div
                    className={cn(
                      "flex justify-center items-center h-[80px] min-w-[80px] rounded-full shadow ms-5",
                      [x.green ? "bg-primary" : "bg-white"]
                    )}
                  >
                    <img src={x.icon} alt="" className="h-[40px] w-[40px]" />
                  </div>
                  <div className=" ms-7">
                    <h1 className="font-medium text-[22px] text-secondary">
                      {x.heading}
                    </h1>
                    <p className="font-light text-[#484848]">{x.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full lg:w-2/5 flex justify-center p-3">
          <img
            className=" object-contain"
            src="/assets/home/why-choose-img.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
