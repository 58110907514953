// import React, { useState } from "react";
// import { FaTelegramPlane } from "react-icons/fa";
// import { Link } from "react-router-dom";

// const Payment = ({ onClick }) => {
//   const [paymentMethod, setPaymentMethod] = useState("creditCard");
//   const [agreed, setAgreed] = useState(false);

//   const handleCreditCardPayment = () => {
//     setPaymentMethod("creditCard");
//   };

//   const handlePayPalPayment = () => {
//     setPaymentMethod("paypal");
//   };

//   const handleAgreementChange = () => {
//     setAgreed(!agreed);
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <h2 className="text-xl font-bold mb-4">Choose Payment Method:</h2>
//       <div className="flex flex-col sm:flex-row gap-3 mb-4">
//         <button
//           className="relative flex flex-col items-center border border-primary text-secondary font-medium p-4 rounded gap-3 sm:w-1/2"
//           onClick={handleCreditCardPayment}
//         >
//           {paymentMethod === "creditCard" && (
//             <div className="absolute h-4 w-4 bg-primary rounded-full left-3 top-3"></div>
//           )}
//           <div className="flex items-center gap-2">
//             <img
//               className="w-12"
//               src="/assets/home/mastercard.png"
//               alt="card"
//             />
//             <img
//               className="w-12"
//               src="/assets/home/american-express.png"
//               alt="card"
//             />
//             <img className="w-12" src="/assets/home/visa.png" alt="card" />
//           </div>
//           <p>Payment with Credit Card</p>
//         </button>
//         <button
//           className="relative flex flex-col items-center border border-primary text-secondary font-medium p-4 rounded gap-3 sm:w-1/2"
//           onClick={handlePayPalPayment}
//         >
//           {paymentMethod === "paypal" && (
//             <div className="absolute h-4 w-4 bg-primary rounded-full left-3 top-3"></div>
//           )}
//           <div className="flex items-center gap-2">
//             <img src="/assets/icons/paypal.png" alt="card" />
//           </div>
//           <p>Payment with PayPal</p>
//         </button>
//       </div>

//       {paymentMethod === "creditCard" && (
//         <div className="border border-primary p-3 rounded-lg">
//           <div className="flex flex-col md:flex-row gap-3 mb-3">
//             <div className="flex gap-2 text-xl flex-col md:w-1/2">
//               <label htmlFor="">Card Holder Name</label>
//               <input type="text" className="px-4 py-2 border rounded" />
//             </div>
//             <div className="flex gap-2 text-xl flex-col md:w-1/2">
//               <label htmlFor="">Card Number</label>
//               <input type="text" className="px-4 py-2 border rounded" />
//             </div>
//           </div>
//           <div className="flex flex-col lg:flex-row gap-3 mb-3">
//             <div className="flex gap-3 lg:w-[calc(50%-6px)]">
//               <div className="flex gap-2 text-xl flex-col w-[calc(50%-6px)]">
//                 <label htmlFor="">Expiry Month</label>
//                 <input type="text" className="px-4 py-2 border rounded" />
//               </div>
//               <div className="flex gap-2 text-xl flex-col w-[calc(50%-6px)]">
//                 <label htmlFor="">Expiry Year</label>
//                 <input type="text" className="px-4 py-2 border rounded" />
//               </div>
//             </div>
//             <div className="flex gap-2 text-xl flex-col lg:w-[calc(50%-6px)]">
//               <label htmlFor="">CVV</label>
//               <input type="text" className="px-4 py-2 border rounded" />
//             </div>
//           </div>

//           <div className="mt-4 flex items-center">
//             <input
//               type="checkbox"
//               checked={agreed}
//               onChange={handleAgreementChange}
//               className="mr-2"
//             />
//             <p className="text-sm text-gray-600">
//               By continuing, you agree to the{" "}
//               <Link to="/terms-of-use">Terms and Conditions</Link>.
//             </p>
//           </div>
//           <div className="flex justify-end">
//             <button
//               type="button"
//               onClick={(e) => onClick(e)}
//               className="flex mt-3 ms-auto w-max items-center border rounded-full bg-primary text-white font-medium flex-none"
//             >
//               <span className="px-4 py-3">Confirm Booking</span>
//               <span className="w-9 h-9 bg-white text-secondary flex justify-center items-center rounded-full me-1">
//                 <FaTelegramPlane color={"#63AB45"} />
//               </span>
//             </button>
//           </div>
//         </div>
//       )}

//       {paymentMethod === "paypal" && (
//         <div>
//           {/* PayPal Standard Payment Form */}
//           <form
//             action="https://www.paypal.com/cgi-bin/webscr"
//             method="post"
//             target="_top"
//           >
//             <button className="flex items-center border p-2 rounded gap-2">
//               <p className="font-medium">Pay with</p>
//               <img src="/assets/icons/paypal.png" alt="" />
//             </button>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Payment;

import React, { useEffect, useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

// Function to fetch the Stripe public key from the API
const fetchStripeKey = async () => {
  const response = await fetch(
    "https://login.flymiles.com.au/api_sections/tours/stripe_public_key.php",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stripe_key: "yes" }),
    }
  );

  const result = await response.json();
  if (response.ok && result.stripe_key) {
    return result.stripe_key;
  } else {
    throw new Error("Failed to fetch Stripe key");
  }
};

const PaymentForm = ({ onClick, id, total }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [error, setError] = useState("");

  const handleAgreementChange = () => {
    setAgreed(!agreed);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    setLoading(true);

    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      // console.log("[error]", error);
      setError(error.message);
      setLoading(false);
    } else {
      // console.log("[Token]", token);
      // Pass the token to your server for processing
      const paymentData = {
        payment_token: token.id,
        amount: total, // Replace with the actual amount
        booking_id: id, // Replace with the actual booking ID
        payment_method: "stripe",
        card_holder_name: cardHolderName,
      };

      // console.log(paymentData);
      try {
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/tours/stripe_payment_api.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(paymentData),
          }
        );

        const result = await response.json();

        if (response.ok && result.success) {
          // console.log("Payment successful:", result);
          onClick(token);
          setLoading(false);
        } else {
          console.error("Payment failed:", result.error);
          setError(result.error);
          setLoading(false);

          // setError(result.error);
        }
      } catch (error) {
        console.error("Error processing payment:", error);
        setError(error.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="border border-primary p-3 rounded-lg"
    >
      <div className="flex flex-col md:flex-row gap-3 mb-3">
        <div className="flex gap-2 text-xl flex-col w-full">
          <label htmlFor="cardHolderName">Card Holder Name</label>
          <input
            type="text"
            id="cardHolderName"
            value={cardHolderName}
            onChange={(e) => setCardHolderName(e.target.value)}
            placeholder="Enter Your Name"
            className="px-4 py-2 border text-sm rounded outline-none"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-3 mb-3">
        <div className="flex gap-2 text-xl flex-col w-full">
          <label htmlFor="cardNumber">Card Number</label>
          <CardElement
            id="card-element"
            options={{ hidePostalCode: true }}
            className="px-4 py-2 border rounded"
          />
        </div>
      </div>

      <div className="mt-4 flex items-center">
        <input
          id="terms"
          type="checkbox"
          checked={agreed}
          onChange={handleAgreementChange}
          className="mr-2"
        />
        <label htmlFor="terms" className="text-sm text-gray-600">
          By continuing, you agree to the{" "}
          <Link to="/terms-of-use" className="text-blue-500 underline">
            Terms and Conditions
          </Link>
          .
        </label>
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={!agreed || loading}
          className="flex mt-3 ms-auto w-max items-center border rounded-full bg-primary text-white font-medium flex-none"
        >
          <span className="px-4 py-3">
            {loading ? "Processing..." : "Process Payment"}
          </span>
          <span className="w-9 h-9 bg-white text-secondary flex justify-center items-center rounded-full me-1">
            <FaTelegramPlane color={"#63AB45"} />
          </span>
        </button>
      </div>
      <div id="card-errors" role="alert"></div>
      {error ? (
        <div className={"flex justify-start mt-4"}>
          <div className="bg-red-100 w-[100%] font-medium text-red-400 py-2 px-5 rounded-md">
            {error}
          </div>
        </div>
      ) : (
        <></>
      )}
    </form>
  );
};

const Payment = ({ onClick, id, total }) => {
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeKey = await fetchStripeKey();
        const stripeInstance = await loadStripe(stripeKey);
        setStripePromise(stripeInstance);
      } catch (error) {
        console.error("Error loading Stripe:", error);
      }
    };

    initializeStripe();
  }, []);

  const handleCreditCardPayment = () => {
    setPaymentMethod("creditCard");
  };

  const handlePayPalPayment = () => {
    setPaymentMethod("paypal");
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Choose Payment Method:</h2>
      <div className="flex flex-col sm:flex-row gap-3 mb-4">
        <button
          className="relative flex flex-col items-center border border-primary text-secondary font-medium p-4 rounded gap-3 sm:w-1/2"
          onClick={handleCreditCardPayment}
        >
          {paymentMethod === "creditCard" && (
            <div className="absolute h-4 w-4 bg-primary rounded-full left-3 top-3"></div>
          )}
          <div className="flex items-center gap-2">
            <img
              className="w-12"
              src="/assets/home/mastercard.png"
              alt="card"
            />
            <img
              className="w-12"
              src="/assets/home/american-express.png"
              alt="card"
            />
            <img className="w-12" src="/assets/home/visa.png" alt="card" />
          </div>
          <p>Payment with Credit Card</p>
        </button>
        <button
          disabled
          className="relative flex flex-col items-center border border-primary text-secondary font-medium p-4 rounded gap-3 sm:w-1/2 opacity-50"
          onClick={handlePayPalPayment}
        >
          {paymentMethod === "paypal" && (
            <div className="absolute h-4 w-4 bg-primary rounded-full left-3 top-3"></div>
          )}
          <div className="flex items-center gap-2">
            <img src="/assets/icons/paypal.png" alt="PayPal" />
          </div>
          <p>Payment with PayPal</p>
        </button>
      </div>

      {paymentMethod === "creditCard" && stripePromise && (
        <Elements stripe={stripePromise}>
          <PaymentForm onClick={onClick} id={id} total={total} />
        </Elements>
      )}

      {paymentMethod === "paypal" && (
        <div>
          {/* PayPal Standard Payment Form */}
          <form
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_top"
          >
            <button className="flex items-center border p-2 rounded gap-2">
              <p className="font-medium">Pay with</p>
              <img src="/assets/icons/paypal.png" alt="PayPal" />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Payment;
