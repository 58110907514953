import React, { useState } from "react";

const CouponCodeInput = () => {
  const [couponCode, setCouponCode] = useState("");

  const handleChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle coupon code submission, e.g., validate, apply, etc.
    // console.log('Coupon code submitted:', couponCode);
  };

  return (
    <div className="max-w-md mt-6 bg-white rounded-md">
      <p className="text-xl font-medium mb-3">Coupon Code</p>
      <form onSubmit={handleSubmit} className="flex">
        <input
          type="text"
          value={couponCode}
          onChange={handleChange}
          placeholder="Enter coupon code"
          className="w-full px-4 py-2 mr-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
        />
        <button
          type="submit"
          className="bg-primary hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
        >
          Apply
        </button>
      </form>
    </div>
  );
};

export default CouponCodeInput;
