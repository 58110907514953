import React, { useState } from "react";
import HeroBanner from "../Sections/HeroBanner";
import { useLocation } from "react-router-dom";
import countries from "../utils/countries.json";

const getCountryName = (countryCode) => {
  const country = countries.find((c) => c.value === countryCode);
  return country ? country.label : countryCode;
};
const mapBookingDetailsToLabels = (details) => [
  { label: "First Name", value: details.user_name },
  { label: "Phone", value: details.user_phone },
  { label: "Email", value: details.user_email },
  { label: "Country", value: getCountryName(details.country) },
  { label: "Pickup Address", value: details.pickup_address },
  { label: "Remarks", value: details.special_requirements },
];

const TourBookingConfim = () => {
  const location = useLocation();
  const { data, bookingDetails, id } = location.state;
  const [due, setDue] = useState(0.0);
  const mappedBookingDetails = mapBookingDetailsToLabels(bookingDetails);

  // console.log(data);
  return (
    <>
      <div className="">
        <HeroBanner
          text={"Confirmation"}
          imgUrl={"/assets/contact/hero-banner.png"}
        />
      </div>
      <div className="container mx-auto">
        <div className="mx-3 py-6">
          <h1 className="text-2xl font-semibold">Confirmation</h1>
          <p className="border-primary border-2 p-4 text-center bg-[#F0F8FF] mt-4">
            Your booking is completed, we will send you confirmation email
            shortly.
          </p>
          <div className="flex items-center gap-2 mt-4">
            <p className="font-medium">Booking Number</p>
            <p className="font-medium">#{id}</p>
          </div>
          <div className="p-4 flex justify-between border gap-4 rounded-lg mt-4">
            <div className="flex flex-col md:flex-row w-[70%] gap-3">
              <div className="border rounded h-max sm:w-[250px] flex items-center justify-center">
                <img
                  className="h-full w-full flex-none object-cover rounded"
                  src={`https://login.flymiles.com.au/api_sections/tours/tour_img/${data.mainImage.map(
                    (img) => img.image
                  )}`}
                  alt=""
                />
              </div>
              <div>
                <h1 className="font-semibold text-xl">
                  {data.Product_Header.product_name}
                </h1>
                <div className="flex items-center gap-4 mt-3">
                  <div className="flex items-center gap-3">
                    <p className="font-semibold">Travel Date:</p>
                    <p>{bookingDetails.booking_date}</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="font-semibold">Pickup Time:</p>
                    <p>{bookingDetails.pickup_time}</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 mt-3">
                  <p className="font-semibold">Duration:</p>
                  <p>{data.Product_Header.durations}</p>
                </div>
              </div>
            </div>
            <h1 className="text-primary font-semibold text-xl">
              ${bookingDetails.net_price}
            </h1>
          </div>
          <div className="rounded p-4 bg-[#F3F8F6] flex items-end flex-col gap-3 mt-6">
            <p>Subtotal: ${bookingDetails.net_price}</p>
            <p>Total: ${bookingDetails.net_price}</p>
            <p>Amount Paid: ${bookingDetails.net_price - due}</p>
            {/* <p>
              Amount Due: $
              {due - bookingDetails.net_price < 0.0
                ? 0.0
                : due - bookingDetails.net_price}
            </p> */}
          </div>
          <div className="overflow-x-auto mt-6">
            <table className="w-full border-collapse border border-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                {mappedBookingDetails.map((item, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                  >
                    <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {item.label}
                    </td>
                    <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourBookingConfim;
