import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const LocationDropdown = ({apiKey}) => {

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "24px",
      "&:hover": {
        boxShadow: "none",
      },
      border: "none !important",
      borderColor: "transparent",
      boxShadow: "none !important",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "18px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#f7921e" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={apiKey}
        autocompletionRequest={{
          types: ['(cities)'],
        }}
        styles={customStyles}
        classNamePrefix="custom-select"
        placeholder="Select City"
      />
    </div>
  );
};

export default LocationDropdown;
