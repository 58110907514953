import React from "react";
import Review from "../Sections/Review";
import WhyChooseUs from "../Sections/WhyChooseUs";
import PopularPlace from "../Sections/PopularPlace";
import HomeHero from "../Sections/HomeHero";
import Fleets from "../Sections/Fleets";

const Home = () => {
  return (
    <div>
      <HomeHero />
      <Fleets />
      <PopularPlace />
      <WhyChooseUs />
      <Review />
    </div>
  );
};

export default Home;
