import React from "react";

const CurrencyDropdown = ({ selectedCurrency, setSelectedCurrency }) => {
  const currencies = ["USD", "AUD"];

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="relative inline-block">
        <select
          id="currency"
          name="currency"
          onChange={handleCurrencyChange}
          value={selectedCurrency}
          className="appearance-none bg-white border-none text-xl font-semibold rounded-md focus:outline-none px-1 lg:px-3"
        >
          {currencies.map((currency) => (
            <option
              key={currency}
              value={currency}
              className="border-b border-gray-200"
            >
              {currency}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          {/* You can customize the icon here or leave it empty */}
        </div>
      </div>
    </div>
  );
};

export default CurrencyDropdown;
