import React from "react";
import Tag from "../Component/Tag";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaUserAlt } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";

const fleetsDetails = [
  {
    name: "Business Class",
    imgUrl: "/assets/home/fleet-business.png",
    sit: 4,
    bag: 3,
  },
  {
    name: "First Class",
    imgUrl: "/assets/home/fleet-first-class.png",
    sit: 4,
    bag: 3,
  },
  {
    name: "Vans/People Movers",
    imgUrl: "/assets/home/fleet-van.png",
    sit: 6,
    bag: 6,
  },
  {
    name: "Mini Bus",
    imgUrl: "/assets/home/fleet-minibus.png",
    sit: 11,
    bag: 11,
  },
];

const Fleets = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    laptop: {
      breakpoint: { max: 1023, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="bg-white border-t-[10px] border-primary py-3 mt-6 lg:mt-0">
      <div className="container mx-auto text-center mb-12 mt-6">
        <div className="flex justify-center mb-12">
          <Tag text={"Our Fleets"} />
        </div>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          arrows={false}
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite
          rtl
        >
          {fleetsDetails.map((x, index) => (
            <div key={index}>
              <h2 className="text-lg font-medium mb-5 text-secondary">
                {x.name}
              </h2> 
              <img src={x.imgUrl} alt={x.name} />
              <div className="flex items-center justify-center gap-3">
                <div className="flex items-center justify-center gap-2">
                  <div className="text-primary">
                    <FaUserAlt />
                  </div>
                  <p className="font-medium text-secondary">{x.sit}</p>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <div className="text-primary">
                    <FaBriefcase />
                  </div>
                  <p className="font-medium text-secondary">{x.bag}</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Fleets;
