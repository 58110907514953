import React, { useState } from "react";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";

const requestExtraDetails = [
  {
    icon: "/assets/icons/luggage.png",
    title: "Extra Luggage",
    text: "1 Carry-on and 1 Check in luggage per person in included in Service. If you have any extra luggage please choose number of bags you would like to add",
    action: "handleLuggage",
  },
  {
    icon: "/assets/icons/surfboard.png",
    title: "Surfboard",
    text: "If you are traveling with extra large item like ",
    action: "handleSurfboard",
  },
  {
    icon: "/assets/icons/baby-seat.png",
    title: "Baby Seats",
    text: "if your are traveling with Child (0 months to 4 Years old)",
    action: "handleBabySeats",
  },
  {
    icon: "/assets/icons/baby-booster.png",
    title: "Baby Booster",
    text: "If you are traveling with child (4 to 7 years old)",
    action: "handleBabyBooster",
  },
];

const RequestExtras = () => {
    const [luggageQnt, setLuggageQnt] = useState(0);
    const [surfboardQnt, setSurfboardQnt] = useState(0);
    const [babySeatsQnt, setBabySeatsQnt] = useState(0);
    const [babyBoosterQnt, setBabyBoosterQnt] = useState(0);

    // Functions to handle state updates
    const handleLuggage = (action) => {
        if (action === "increment") {
            setLuggageQnt(prevState => prevState + 1);
        } else if (action === "decrement" && luggageQnt > 0) {
            setLuggageQnt(prevState => prevState - 1);
        }
    };

    const handleSurfboard = (action) => {
        if (action === "increment") {
            setSurfboardQnt(prevState => prevState + 1);
        } else if (action === "decrement" && surfboardQnt > 0) {
            setSurfboardQnt(prevState => prevState - 1);
        }
    };

    const handleBabySeats = (action) => {
        if (action === "increment") {
            setBabySeatsQnt(prevState => prevState + 1);
        } else if (action === "decrement" && babySeatsQnt > 0) {
            setBabySeatsQnt(prevState => prevState - 1);
        }
    };

    const handleBabyBooster = (action) => {
        if (action === "increment") {
            setBabyBoosterQnt(prevState => prevState + 1);
        } else if (action === "decrement" && babyBoosterQnt > 0) {
            setBabyBoosterQnt(prevState => prevState - 1);
        }
    };

  return (
    <div className="mt-6 border rounded-lg p-3">
      <h2 className="font-semibold text-xl">Request Extras</h2>
      <p className="mt-3 text-sm">
        You have 1 checked and 1 carry-on bag included in your transfer, would
        you like to request extra luggage or other items for your booking?
      </p>
      {requestExtraDetails.map((x, index) => (
        <div
          key={index}
          className="relative flex justify-between items-center mt-6 gap-6"
        >
          <div className="flex flex-col sm:flex-row sm:items-center gap-3">
            <img
              src={x.icon}
              alt=""
              className="flex-none w-16 object-contain"
            />
            <div className="flex flex-col gap-2">
              <h1 className="font-semibold">{x.title}</h1>
              <p className="text-sm">{x.text}</p>
            </div>
          </div>
          <div className="flex gap-2 items-center justify-between border rounded px-2">
            <input
              className="absolute right-0 top-0 sm:relative flex-none focus:outline-none w-9 h-9"
              type="number"
              value={x.action === "handleLuggage" ? luggageQnt : x.action === "handleSurfboard" ? surfboardQnt : x.action === "handleBabySeats" ? babySeatsQnt : babyBoosterQnt}
              readOnly
            />
            <div className="flex items-center gap-1">
              <button
                onClick={() => x.action === "handleLuggage" ? handleLuggage("decrement") : x.action === "handleSurfboard" ? handleSurfboard("decrement") : x.action === "handleBabySeats" ? handleBabySeats("decrement") : handleBabyBooster("decrement")}
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMinusSm />
              </button>
              <button
                onClick={() => x.action === "handleLuggage" ? handleLuggage("increment") : x.action === "handleSurfboard" ? handleSurfboard("increment") : x.action === "handleBabySeats" ? handleBabySeats("increment") : handleBabyBooster("increment")}
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiPlusSm />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RequestExtras;
