import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SectionHeading from "../Component/SectionHeading";
import { FaMapMarkerAlt } from "react-icons/fa";
import { PiShareFatLight } from "react-icons/pi";
import { CiHeart } from "react-icons/ci";
import { BiSolidBadgeCheck } from "react-icons/bi";
import TourPlaceCard from "../Component/TourPlaceCard";
import RatingStars from "../Component/RatingStars";
import RichTextDisplay from "../Component/RichTextDisplay";
import BookingPackage from "../Component/BookingPackage";

const TourBookingDetails = () => {
  const location = useLocation();
  const tourData = location.state?.packageData;
  const tourDataNavigate = useNavigate();
  const tourDataImgs = tourData?.images?.map((img) => img.image) || [];
  const [days, setDays] = useState(0);
  // console.log(tourDataImgs);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
    },
  };

  const accessibilities =
    tourData?.accessibility?.filter((item) => item.yes_no) || [];

  const healthRestrictions =
    tourData?.health_restrication?.filter(
      (item) => item.check_uncheck === "yes"
    ) || [];

  const relatedTourResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  const handleTourBooking = (e, tourData) => {
    tourDataNavigate(`/tour-booking`, { state: { tourData } });
  };

  // const calculateDaysBetweenDates = (startDate, endDate) => {
  //   const start = new Date(startDate);
  //   const end = new Date(endDate);
  //   const differenceInTime = end - start;
  //   const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  //   return differenceInDays;
  // };

  // useEffect(() => {
  //   const days = calculateDaysBetweenDates(
  //     tourData?.Product_Header?.start_date,
  //     tourData?.Product_Header?.end_date
  //   );
  //   setDays(days);
  //   console.log(days);
  // }, []);

  return (
    <div>
      <div className="mt-[88px]">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          arrows={false}
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite
          keyBoardControl={true}
        >
          {tourDataImgs.map((imgUrl, index) => (
            <img
              key={index}
              className="h-[60vh] w-full object-cover px-3"
              src={`https://login.flymiles.com.au/api_sections/packages/pkg_img/${imgUrl}`}
              alt={`Img ${index + 1}`}
            />
          ))}
        </Carousel>
      </div>
      <div className="container mx-auto">
        <div className="mx-3 py-12">
          <div className="flex flex-col lg:flex-row lg:items-center justify-between pb-12 border-b gap-4">
            <div>
              <SectionHeading text={tourData.Product_Header.product_name} />
              <div className="flex items-center gap-2 mt-4">
                <FaMapMarkerAlt color="#63AB45" />
                <p>
                  {tourData.cities.map((city, index) => (
                    <span key={index}>
                      {city.city_name}
                      {index < tourData.cities.length - 1 ? ", " : ""}
                    </span>
                  ))}

                  {/* {tourData.location}, {tourData.locationCountry} */}
                </p>
              </div>
            </div>
            <div className="flex items-center flex-wrap gap-4">
              <div className="flex items-center gap-2">
                <img
                  className="h-9 w-9 object-contain"
                  src={"/assets/icons/from.png"}
                  alt="from"
                />
                <div>
                  <p>From</p>
                  <p className="font-semibold text-xl">
                    ${tourData.Product_Header.price_adult}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img
                  className="h-9 w-9 object-contain"
                  src={"/assets/icons/day.png"}
                  alt="day"
                />
                <div>
                  <p>Duration</p>
                  <p className="font-semibold text-xl">
                    {tourData?.daywise?.length} Days
                  </p>
                </div>
              </div>
              {/* <div className="flex items-center gap-2">
                <img
                  className="h-9 w-9 object-contain"
                  src={"/assets/icons/day.png"}
                  alt="day"
                />
                <div>
                  <p>Duration</p>
                  <p className="font-semibold text-xl">
                    {tourData.duration}
                    Days
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img
                  className="h-9 w-9 object-contain"
                  src={"/assets/icons/city.png"}
                  alt="city"
                />
                <div>
                  <p>Tour Type</p>
                  <p className="font-semibold text-xl">
                    {tourData.tourType}
                    tourtype
                  </p>
                </div>
              </div> */}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center justify-between py-4 border-b gap-4">
            <div className="flex items-center text-[#F7921E]">
              <RatingStars star={5} />
              <p className="text-secondary font-medium ms-1">(5.0 Reviews)</p>
            </div>
            <div className="flex items-center flex-wrap gap-4">
              {/* <Link
                to={"/"}
                className="py-3 px-4 flex items-center gap-2 bg-primary rounded-full text-white"
              >
                <p className="uppercase">Share</p>
                <PiShareFatLight />
              </Link> */}
              {/* <Link
                to={"/"}
                className="py-3 px-4 flex items-center gap-2 bg-[#E8E9E9] rounded-full"
              >
                <p className="uppercase">Share</p>
                <PiShareFatLight />
              </Link>
              <Link
                to={"/"}
                className="py-3 px-4 flex items-center gap-2 bg-[#E8E9E9] rounded-full"
              >
                <p className="uppercase">Wishlist</p>
                <CiHeart />
              </Link> */}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-6 mx-3 py-12">
            <div className="lg:w-[calc(100%-374px)]">
              <SectionHeading
                text={"Explore Tour"}
                size={"2xl"}
                leading={"28px"}
              />

              <RichTextDisplay
                rawDraftContentState={
                  tourData.Product_Header.prodcut_inclusion_high
                }
              />
              <SectionHeading
                text={"Tour Plan"}
                size={"2xl"}
                leading={"28px"}
                mt={6}
              />
              <div className="relative">
                <div className="h-full w-[3px] -z-10 bg-primary left-4 absolute"></div>
                <div className="mt-6">
                  {tourData?.daywise?.map((x, index) => (
                    <div key={index} className="flex gap-3">
                      <div className="flex-none w-9 h-9 flex items-center justify-center">
                        {index === 0 ? (
                          <p className="h-8 w-8 rounded-full flex items-center justify-center font-semibold text-white bg-primary -mt-1">
                            {index + 1}
                          </p>
                        ) : (
                          <p className="h-8 w-8 rounded-full flex items-center justify-center font-semibold text-white bg-primary -mt-1">
                            {index + 1}
                          </p>
                        )}
                      </div>
                      <div>
                        <h1 className="font-semibold text-xl">{x.header}</h1>
                        <p className="my-3">{x.body}</p>
                        <div className="flex items-center gap-3 mb-4 -mt-2">
                          {x.duration && (
                            <p className="text-sm">{x.duration}</p>
                          )}
                          {x.include && (
                            <p className="flex items-center gap-2 text-sm">
                              <span className="w-1 h-1 bg-secondary rounded-full"></span>{" "}
                              {x.include}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <SectionHeading
                size={"2xl"}
                leading={"28px"}
                mt={6}
                text={"Inclusions"}
              />
              <div className="flex gap-4 mt-4">
                <div className="md:w-[calc(100%-8px)] flex-none">
                  {healthRestrictions?.map((x, index) => (
                    <div
                      key={index}
                      className="flex text-xl mb-2 gap-2 items-center"
                    >
                      <BiSolidBadgeCheck color="#63AB45" />
                      <p className="font-medium text-sm">{x.title}</p>
                    </div>
                  ))}
                </div>
              </div>
              <SectionHeading
                text={"Accessibilities"}
                size={"2xl"}
                leading={"28px"}
                mt={6}
              />
              <div className="flex gap-4 mt-4">
                <div className="md:w-[calc(100%-8px)] flex-none">
                  {accessibilities?.map((x, index) => (
                    <div
                      key={index}
                      className="flex text-xl mb-2 gap-2 items-center"
                    >
                      <BiSolidBadgeCheck
                        color={x.yes_no === "yes" ? "#63AB45" : "#1C231Fa2"}
                      />
                      <p className="font-medium text-sm">
                        {x.title} {x.yes_no ? x.yes_no.toUpperCase() : "none"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              {/* <SectionHeading text={"Related Tours"} mt={12} />
              <div className="mt-6 text-left">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  arrows={false}
                  responsive={relatedTourResponsive}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  infinite
                  itemClass="related-card-style"
                >
                  {tourPlaceInfos.map((x, index) => (
                    <div key={index}>
                      <TourPlaceCard
                        cardImg={x.cardImg}
                        featured={x.featured}
                        off={x.off}
                        rating={x.rating}
                        title={x.title}
                        price={x.price}
                        place={x.place}
                        day={x.day}
                        people={x.people}
                        exploreBtnLink={x.exploreBtnLink}
                      />
                    </div>
                  ))}
                </Carousel>
              </div> */}
            </div>
            <div className="lg:w-[350px]">
              <BookingPackage
                onClick={(e) => e}
                location={tourData?.locationCountry}
                language={tourData?.language}
                data={tourData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourBookingDetails;
