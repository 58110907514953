import React, { useState } from "react";
import { IoFilterOutline } from "react-icons/io5";

const TransferFilter = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [types, setTypes] = useState({
    shared: { title: "Shared", checked: false, count: 12 },
    private: { title: "Private", checked: false, count: 9 },
    rideShare: { title: "Ride Share", checked: false, count: 5 },
  });

  const [vehicle, setVehicle] = useState({
    sedan: { title: "Sedan", checked: false, count: 12 },
    suv: { title: "SUV", checked: false, count: 9 },
    limu: { title: "Limu", checked: false, count: 5 },
    van: { title: "Van", checked: false, count: 12 },
    bus: { title: "Bus", checked: false, count: 9 },
  });

  const [vehicleClass, setVehicleClass] = useState({
    economy: { title: "Economy", checked: false, count: 12 },
    business: { title: "Business", checked: false, count: 9 },
    luxury: { title: "Luxury", checked: false, count: 5 },
  });

  const [inclusion, setInclusion] = useState({
    meet: { title: "Meet & Greet", checked: false, count: 12 },
    covid: { title: "COVID-19 Prepared", checked: false, count: 9 },
  });

  const [rating, setRating] = useState({
    any: { title: "Any", checked: false, count: 12 },
    wonderful: { title: "Wonderfull 4.5+", checked: false, count: 9 },
    veryGood: { title: "Very Good 4+", checked: false, count: 9 },
    good: { title: "Good 3.5+", checked: false, count: 5 },
  });

  const [reviews, setReviews] = useState({
    fifty: { title: "50 or more", checked: false, count: 12 },
    tweentyFive: { title: "25 or more", checked: false, count: 9 },
    ten: { title: "10 or more", checked: false, count: 5 },
    five: { title: "5 or more", checked: false, count: 5 },
  });

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  const handleTypesChange = (event) => {
    const { name, checked } = event.target;
    setTypes({ ...types, [name]: { ...types[name], checked } });
  };

  const handleVehicleChange = (event) => {
    const { name, checked } = event.target;
    setVehicle({ ...vehicle, [name]: { ...vehicle[name], checked } });
  };

  const handleVehicleClassChange = (event) => {
    const { name, checked } = event.target;
    setVehicleClass({
      ...vehicleClass,
      [name]: { ...vehicleClass[name], checked },
    });
  };

  const handleInclusionChange = (event) => {
    const { name, checked } = event.target;
    setInclusion({ ...inclusion, [name]: { ...inclusion[name], checked } });
  };

  const handleRatingChange = (event) => {
    const { name, checked } = event.target;
    setRating({ ...rating, [name]: { ...rating[name], checked } });
  };

  const handleReviewChange = (event) => {
    const { name, checked } = event.target;
    setReviews({ ...reviews, [name]: { ...reviews[name], checked } });
  };

  return (
    <div className="relative lg:inline-block lg:relative">
      <button
        className="flex -mt-[9px] items-center gap-1 border rounded-full py-2 px-4 lg:hidden"
        onClick={toggleFilter}
      >
        <IoFilterOutline />
        <p>Filter</p>
      </button>
      <div
        onClick={toggleFilter}
        className={`block lg:hidden fixed h-lvh w-lvw bg-[#00000090] z-50 top-0 ${
          isOpen ? "left-0" : "-left-full"
        }`}
      ></div>
      <div
        className={`fixed top-0 left-0 h-lvh lg:h-max z-50 lg:relative bg-white border border-gray-300 p-4 lg:mt-2 rounded w-80 ${
          isOpen ? "block" : "hidden"
        } lg:block`}
      >
        <div className="mb-6">
          <h3 className="font-bold mb-3">Types</h3>
          <ul>
            {Object.entries(types).map(([name, { title, checked, count }]) => (
              <li key={name} className="mb-2 flex justify-between items-center">
                <div className="flex items-center">
                  <input
                    type="checkbox" 
                    name={name}
                    checked={checked}
                    onChange={handleTypesChange}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label htmlFor={name} className="ml-2">
                    {title}
                  </label>
                </div>
                <span>{count}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="mb-6">
          <h3 className="font-bold mb-3">Vehicle</h3>
          <ul>
            {Object.entries(vehicle).map(
              ([name, { title, checked, count }]) => (
                <li
                  key={name}
                  className="mb-2 flex justify-between items-center"
                >
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name={name}
                      checked={checked}
                      onChange={handleVehicleChange}
                      style={{ width: "16px", height: "16px" }}
                    />
                    <label htmlFor={name} className="ml-2">
                      {title}
                    </label>
                  </div>
                  <span>{count}</span>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="mb-6">
          <h3 className="font-bold mb-3">Class</h3>
          <ul>
            {Object.entries(vehicleClass).map(
              ([name, { title, checked, count }]) => (
                <li
                  key={name}
                  className="mb-2 flex justify-between items-center"
                >
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name={name}
                      checked={checked}
                      onChange={handleVehicleClassChange}
                      style={{ width: "16px", height: "16px" }}
                    />
                    <label htmlFor={name} className="ml-2">
                      {title}
                    </label>
                  </div>
                  <span>{count}</span>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="mb-6">
          <h3 className="font-bold mb-3">Inclusion & Extras</h3>
          <ul>
            {Object.entries(inclusion).map(
              ([name, { title, checked, count }]) => (
                <li
                  key={name}
                  className="mb-2 flex justify-between items-center"
                >
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name={name}
                      checked={checked}
                      onChange={handleInclusionChange}
                      style={{ width: "16px", height: "16px" }}
                    />
                    <label htmlFor={name} className="ml-2">
                      {title}
                    </label>
                  </div>
                  <span>{count}</span>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="mb-6">
          <h3 className="font-bold mb-3">Rating</h3>
          <ul>
            {Object.entries(rating).map(([name, { title, checked, count }]) => (
              <li key={name} className="mb-2 flex justify-between items-center">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name={name}
                    checked={checked}
                    onChange={handleRatingChange}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label htmlFor={name} className="ml-2">
                    {title}
                  </label>
                </div>
                <span>{count}</span>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="font-bold mb-3">Number of review</h3>
          <ul>
            {Object.entries(reviews).map(
              ([name, { title, checked, count }]) => (
                <li
                  key={name}
                  className="mb-2 flex justify-between items-center"
                >
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name={name}
                      checked={checked}
                      onChange={handleReviewChange}
                      style={{ width: "16px", height: "16px" }}
                    />
                    <label htmlFor={name} className="ml-2">
                      {title}
                    </label>
                  </div>
                  <span>{count}</span>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TransferFilter;
