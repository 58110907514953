import React, { useState, useEffect } from "react";
import HeroBanner from "../Sections/HeroBanner";
import Tag from "../Component/Tag";
import PackagePagination from "../Component/PackagePagination";
import PackageSingleResult from "../Component/PackageSingleResult";
import PackageSearch from "../Component/PackageSearch";
import { useNavigate, useLocation } from "react-router-dom";

const PackageSearchResult = () => {
  const itemsPerPage = 4;

  const navigate = useNavigate();
  const handlePackageClick = (e, packageData) => {
    navigate(`/package-search/${packageData.Product_Header.id}`, {
      state: { packageData },
    });
  };
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  // Access the state data passed through navigation
  const data = location?.state?.data;
  const selectedDate = location?.state?.selectedDate;

  useEffect(() => {
    if (data !== undefined) {
      setLoading(false);
    }
  }, [data]);

  // console.log(data);
  const formattedData = Array.isArray(data)
    ? data?.map((packageData, index) => (
        <div key={index}>
          <PackageSingleResult
            id={packageData.Product_Header.id}
            imgUrl={`https://login.flymiles.com.au/api_sections/packages/pkg_img/${packageData.mainImage.map(
              (img) => img.image
            )}`}
            title={packageData.Product_Header.product_name}
            star={packageData.star}
            location={packageData.cities}
            // day={packageData.daywise}
            // night={packageData.daywise}
            price={packageData.Product_Header.price_adult}
            includesHighlight={packageData.inclusions}
            // packageIncludes={packageData.inclusions}
            onClick={(id) => handlePackageClick(id, packageData)}
            // smallText={packageData.Product_Header.total_price}
          />
        </div>
      ))
    : [];

  return (
    <>
      <div className="hidden lg:block">
        <HeroBanner
          text={"Search"}
          imgUrl={"/assets/contact/hero-banner.png"}
        />
      </div>
      <div className="flex justify-center w-full lg:absolute pt-[100px] pb-12 lg:py-0 lg:-mt-12">
        <PackageSearch resultPage />
      </div>
      <div className="container mx-auto lg:pt-20 lg:pb-8">
        <div className="mx-3">
          {loading ? (
            <Tag text={"Loading..."} center />
          ) : data?.error === "No Prodcut Found" ? (
            <Tag text={"No Search Result"} center />
          ) : data?.length > 0 ? (
            <Tag text={"Your Search Result"} center />
          ) : (
            <Tag text={"No Search Result"} center />
          )}

          <div className="mt-6">
            <PackagePagination
              data={formattedData}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageSearchResult;
