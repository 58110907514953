import React from "react";
import HeroBanner from "../Sections/HeroBanner";
import TransfersSearch from "../Component/TransfersSearch";
import Tag from "../Component/Tag";
import TransferFilter from "../Component/TransferFilter";
import TransferPagination from "../Component/TransferPagination";
import transferResultData from "../mocks/TransferResult.json";
import TransferSingleResult from "../Component/TransferSingleResult";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { IoLockClosedOutline } from "react-icons/io5";
import { CiFaceSmile, CiShoppingCart } from "react-icons/ci";
import { IoTicketOutline } from "react-icons/io5";

const whySydneyTransfer = [
  {
    icon: <CiFaceSmile />,
    title: "100% Refund Guarantee",
    text: "Cancel up to 48 hours before you travel.",
  },
  {
    icon: <IoTicketOutline />,
    title: "Fixed Pricing",
    text: "No Surge, No Meter, No Surprises",
  },
  {
    icon: <CiShoppingCart />,
    title: "Extra Luggage Options",
    text: "For When one bag is not enough",
  },
  {
    icon: <IoLockClosedOutline />,
    title: "Safe & Secure Payment",
    text: "Master Card, Paypal, American Express, Visa",
  },
];

const TransferSearchResult = () => {
  const itemsPerPage = 3; // Number of items per page

  const transferNavigate = useNavigate();
  const handlePayLater = (e, transferData) => {
    transferNavigate(`/transfer-search/${transferData.id}`, {
      state: { transferData },
    });
  };

  const handlePayNow = (e, transferData) => {
    transferNavigate(`/transfer-search/${transferData.id}`, {
      state: { transferData },
    });
  };

  // Format tour data for pagination
  const formattedData = transferResultData.map((transferData, index) => (
    <div key={index}>
      <TransferSingleResult
        id={transferData.id}
        imgUrl={transferData.imgUrl}
        title={transferData.title}
        vehicleType={transferData.vehicleType}
        vehicleCategory={transferData.vehicleCategory}
        vehicleClass={transferData.vehicleClass}
        inclusion={transferData.inclusion}
        numberOfReview={transferData.numberOfReview}
        star={transferData.star}
        price={transferData.price}
        route={transferData.route}
        traveDate={transferData.travelDate}
        travelTime={transferData.travelTime}
        distance={transferData.distance}
        duration={transferData.duration}
        handlePayLater={(e) => handlePayLater(e, transferData)}
        handlePayNow={(e) => handlePayNow(e, transferData)}
      />
    </div>
  ));

  return (
    <>
      <div className="hidden lg:block">
        <HeroBanner
          text={"Search"}
          imgUrl={"/assets/contact/hero-banner.png"}
        />
      </div>
      <div className="flex justify-center w-full lg:absolute pt-[100px] pb-12 lg:py-0 lg:-mt-12">
        <TransfersSearch resultPage />
      </div>
      <div className="container mx-auto lg:pt-20 lg:pb-8">
        <div className="mx-3">
          <Tag text={"Your Search Result"} center />
          <div className="mt-12 lg:flex gap-6 relative">
            <div className="lg:w-1/3 ">
              <div className="absolute lg:relative right-0">
                <TransferFilter />
              </div>
              <div className="hidden lg:block border rounded w-80 p-4 mt-6">
                <h3 className="font-bold mb-3">
                  Why book on Sydney Transfers?
                </h3>
                {whySydneyTransfer.map((x, index) => (
                  <div key={index} className="flex gap-3 mt-4">
                    <div className="h-10 w-10 flex flex-none bg-primary text-white justify-center items-center rounded-full text-2xl">
                      {x.icon}
                    </div>
                    <div>
                      <p className="font-semibold">{x.title}</p>
                      <p className="">{x.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="lg:w-2/3 pb-12">
              <TransferPagination
                data={formattedData}
                itemsPerPage={itemsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferSearchResult;
