import React from "react";
import Tag from "../Component/Tag";
import SectionHeading from "../Component/SectionHeading";
import { FaTelegramPlane } from "react-icons/fa";
import WhyChooseUs from "../Sections/WhyChooseUs";
import HeroBanner from "../Sections/HeroBanner";
import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";
import { IconBase } from "react-icons";

const AboutUs = () => {
  // Determine parallax strength based on screen width
  const parallaxStrength = window.innerWidth >= 940 ? 200 : 100;

  return (
    <>
      <HeroBanner
        text={"About Us"}
        imgUrl={"/assets/contact/hero-banner.png"}
      />
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-6 items-center lg:items-end mx-3 mt-12">
          <div className="lg:w-2/3">
            <Tag text={"About Company"} />
            <SectionHeading
              mt={6}
              text={
                "Committed to Elevating Your Travel Experience: Simplifying and Enhancing Every Journey."
              }
            />
            <p className="mt-6 me-0 lg:me-6 text-justify">
              Based in Australia, our Destination Management Company boasts its
              headquarters in Sydney, complemented by representative offices in
              India. We specialize in delivering premium ground handling
              services to Online Travel Agencies (OTA), hotels, tour operators,
              and travel agents for both FIT and Group bookings, offering a
              wealth of local knowledge and expertise..
              <br />
              <br />
              Established in the travel industry since February 2015, we have
              emerged as a prominent name and a key player in the market as a
              ground handling wholesaler for FIT and Groups. Our reputation is
              built on a foundation of exceptional customer satisfaction, and we
              have strategically centred our business around this core
              principle. Our unwavering commitment to exceptional customer
              satisfaction underscores every aspect of our business strategy.
              Whether orchestrating a customized holiday or meticulously
              planning a group trip, we guarantee a commitment to quality
              service to ensure your journey is not only memorable but
              effortlessly executed.
              <br />
              <br />
              At the heart of our customer satisfaction philosophy lies a key
              element: Flexibility. This serves as our secret ingredient,
              allowing us to adapt seamlessly to the unique needs and
              preferences of our clients, further enhancing their overall travel
              experience.
            </p>
          </div>
          <div className="lg:w-1/3">
            <img
              className="w-full h-[420px] sm:h-[570px] object-cover rounded-tl-full rounded-tr-full max-w-[691px]"
              src="/assets/about/1.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-6 items-center lg:items-end mx-3 mt-12">
          <div className="lg:w-1/3 order-2 lg:order-1">
            <img
              className="w-full h-[420px] sm:h-[570px] object-cover rounded-tl-full rounded-tr-full max-w-[691px]"
              src="/assets/about/2.jpeg"
              alt=""
            />
          </div>
          <div className="lg:w-2/3 order-1 lg:order-2">
            <Tag text={"Our Mission"} />
            <SectionHeading
              mt={6}
              text={"Seamless Travel Solutions with Complete Flexibility"}
            />
            <p className="mt-6 me-0 lg:me-6  text-justify">
              We offer a comprehensive range of services encompassing airport
              and cruise terminal transportation, sightseeing excursions, tours,
              and inclusive land packages for inbound travel. Our streamlined
              online booking system ensures a hassle-free experience with
              instant confirmations and vouchers.
              <br />
              <br />
              Recognizing that our customers invest both time and resources in
              planning their trips, we acknowledge the importance of flexible
              itineraries that contribute to a relaxing and enriching travel
              experience. Our commitment to customer satisfaction is reflected
              in our willingness to accommodate changes in bookings, recognizing
              the evolving needs and preferences of our clientele.
              <br />
              <br />
              Our unwavering commitment is to elevate both your and your
              customers' travel experiences to the extraordinary. Through
              meticulous attention to detail and a dedication to customer
              satisfaction, we strive to make every journey unforgettable. Happy
              travelling!
            </p>
          </div>
        </div>
      </div>

      <div className="relative h-[300px] overflow-hidden mt-12">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage="/assets/about/3.png"
          bgImageAlt="the cat"
          strength={parallaxStrength} // Use calculated parallax strength
        >
          <div className="flex flex-col items-center justify-center h-[300px] text-white">
            <h1 className="text-2xl font-bold mb-8">Travel with Fly Miles</h1>
            <Link
              to={"/"}
              className="flex items-center border rounded-full bg-[#F7921E] text-white font-medium"
            >
              <span className="px-4 py-3">Go to Home</span>
              <span className="w-9 h-9 bg-white text-secondary flex justify-center items-center rounded-full me-1">
                <FaTelegramPlane color={"#63AB45"} />
              </span>
            </Link>
          </div>
        </Parallax>
      </div>
      <div className="container mx-auto">
        <WhyChooseUs />
      </div>
    </>
  );
};

export default AboutUs;

// booking issue - city 6
// support Icon
// popular tour loading
// max min cindition
// form fiels
// error success
// console remove

// bk popular tour
