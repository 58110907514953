import React from "react";
import Tag from "../Component/Tag";
import HeroBanner from "../Sections/HeroBanner";
import SectionHeading from "../Component/SectionHeading";

const servicesDetails = [
    {
      imgUrl1: "/assets/home/hero-3.jpg",
      imgUrl2: "/assets/home/hero-2.jpg",
      imgUrl3: "/assets/home/hero-5.jpg",
      title: "Tours",
      text: "We have several vehicles to facilitate your tours in Shared or Private basis, offering sightseeing and day tours together with our regional partners, in all major cities and destinations within Australia.",
      mt: "lg:mt-[192px]"
    },
  {
    imgUrl1: "/assets/home/hero-1.jpg",
    imgUrl2: "/assets/home/hero-2.jpg",
    imgUrl3: "/assets/home/hero-3.jpg",
    title: "Transfers",
    text: "We provide Transport from Airport, Cruise Terminal, Point to Point, Vehicle at Disposal Services in Sydney, Melbourne, Brisbane, Gold Coast and Cairns.",
    mt: "lg:mt-[48px]",
    reverse: true
  },
  {
    imgUrl1: "/assets/home/hero-1.jpg",
    imgUrl2: "/assets/home/hero-6.jpg",
    imgUrl3: "/assets/home/hero-4.jpg",
    title: "Packages",
    text: "We provide Land and Multi-day Packages within Australia and we can also customize itineraries and even provide rates for Group Departures.",
    mt: "lg:mt-[24px]"
  },
  {
    imgUrl1: "/assets/home/hero-5.jpg",
    imgUrl2: "/assets/home/hero-4.jpg",
    imgUrl3: "/assets/home/hero-6.jpg",
    title: "Mice",
    text: "We are your creative partner, working with you to deliver world-class innovative corporate or marriage events.",
    mt: "lg:-mt-[120px]",
    reverse: true
  },
];

const Services = () => {
  return (
    <>
      <HeroBanner
        text={"Our Services"}
        imgUrl={"/assets/contact/hero-banner.png"}
      />
      <div className="container mx-auto">
        <div className="mx-3 py-12">
          <Tag center text="Services" />
          <SectionHeading mt={"6"} center text={"Our Services"} />
          <div className="flex flex-start gap-6 flex-wrap mt-12 lg:mt-0">
            {servicesDetails.map((x, index) => (
              <div className={`${x.mt} ${x.reverse ? "flex-row-reverse lg:flex-row" : ""} relative sm:flex items-center h-max gap-6 lg:w-[calc(50%-12px)] shadow-lg p-6 rounded-lg border`} key={index}>
                <div className={`sm:w-[calc(60%-12px)] ${x.reverse ? "sm:text-end lg:text-start" : ""}`}>
                  <h1 className="font-semibold text-2xl mb-3">{x.title}</h1>
                  <p className="mb-6">{x.text}</p>
                </div>
                <div className="flex justify-center sm:block sm:w-[calc(40%-12px)] relative">
                  <img
                    className="w-[130px] h-[150px] object-cover rounded-lg border-2 border-white relative z-20 sm:left-[50%] sm:translate-x-[-50%] -rotate-12 sm:rotate-6"
                    src={x.imgUrl1}
                    alt="img"
                  />
                  <img
                    className="w-[130px] h-[150px] object-cover rounded-lg border-2 border-white sm:-mt-12 relative z-0 sm:left-[50%] sm:translate-x-[-50%] rotate-6 sm:-rotate-12"
                    src={x.imgUrl3}
                    alt="img"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
