import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Page from "../Component/Page";
import Home from "./Home";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import TourSearchResult from "./TourSearchResult";
import PackageSearchResult from "./PackageSearchResult";
import PackageInquires from "./PackageInquires";
import TransferSearchResult from "./TransferSearchResult";
import TransferBooking from "./TransferBooking";
import TransferBookingConfim from "./TransferBookingConfim";
import TourBookingDetails from "./TourBookingDetails";
import TourBooking from "./TourBooking";
import TourBookingConfim from "./TourBookingConfirm";
import Login from "./Login";
import Register from "./Register";
import Services from "./Services";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";
import GetBooking from "./GetBooking";
import AgentRegistration from "./AgentRegistration";
import AgentLogin from "./AgentLogin";

const Routing = () => {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/",
      element: (
        <Page>
          <Home />
        </Page>
      ),
    },
    {
      path: "/tour-search",
      element: (
        <Page>
          <TourSearchResult />
        </Page>
      ),
    },
    {
      path: "/tour-search/:id",
      element: (
        <Page>
          <TourBookingDetails />
        </Page>
      ),
    },
    {
      path: "/tour-booking",
      element: (
        <Page>
          <TourBooking />
        </Page>
      ),
    },
    {
      path: "/tour-booking-confirm",
      element: (
        <Page>
          <TourBookingConfim />
        </Page>
      ),
    },
    {
      path: "/package-search",
      element: (
        <Page>
          <PackageSearchResult />
        </Page>
      ),
    },
    {
      path: "/package-search/:id",
      element: (
        <Page>
          <PackageInquires />
        </Page>
      ),
    },
    {
      path: "/transfer-search",
      element: (
        <Page>
          <TransferSearchResult />
        </Page>
      ),
    },
    {
      path: "/transfer-search/:id",
      element: (
        <Page>
          <TransferBooking />
        </Page>
      ),
    },
    {
      path: "/transfer-booking-confirm/",
      element: (
        <Page>
          <TransferBookingConfim />
        </Page>
      ),
    },
    {
      path: "/about-us",
      element: (
        <Page>
          <AboutUs />
        </Page>
      ),
    },
    {
      path: "/get-booking",
      element: (
        <Page>
          <GetBooking />
        </Page>
      ),
    },
    {
      path: "/services",
      element: (
        <Page>
          <Services />
        </Page>
      ),
    },
    {
      path: "/contact-us",
      element: (
        <Page>
          <ContactUs />
        </Page>
      ),
    },
    {
      path: "/terms-of-use",
      element: (
        <Page>
          <Terms />
        </Page>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <Page>
          <PrivacyPolicy />
        </Page>
      ),
    },
    {
      path: "/agent-registration",
      element: (
        <Page>
          <AgentRegistration />
        </Page>
      ),
    },
    {
      path: "/agent-login",
      element: (
        <Page>
          <AgentLogin />
        </Page>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routing;
