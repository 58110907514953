import React, { useState, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";

const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Simulate website loading
    }, 2000); // Adjust the time as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`fixed z-[9999] top-0 left-0 w-full h-full flex items-center justify-center bg-white ${
        loading ? "" : "hidden"
      }`}
    >
      <ThreeCircles
        visible={true}
        height={100}
        width={100}
        color="#4fa94d"
        ariaLabel="three-circles-loading"
      />
    </div>
  );
};

export default Loader;
