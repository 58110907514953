import React from 'react';
import { GoChevronRight } from "react-icons/go";

const HeroBanner = ({text, imgUrl}) => {
    return (
        <div className='mt-[88px]'
        style={{
          background: `url(${imgUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}>
            <div className="container px-3 mx-auto">
                <div className="flex items-center justify-center flex-col h-[350px]">
                    <h1 className='text-white text-center leading-[52px] mb-3 text-[48px] font-medium'>{text}</h1>
                    <div className="flex gap-2 items-center text-white text-">
                        <p>Home</p>
                        <GoChevronRight />
                        <p>{text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroBanner;