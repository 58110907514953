import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "./TimePicker";
import { FaCircleCheck } from "react-icons/fa6";
import Button from "./Button";
import { FaRegUserCircle } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import RatingStars from "./RatingStars";
import { HiMiniMinusSmall, HiMiniPlusSmall } from "react-icons/hi2";
import Select, { components } from "react-select";
import Tag from "./Tag";
import { BiSolidBadgeCheck } from "react-icons/bi";
import countries from "../utils/countries.json";
import { format } from "date-fns";
import PhoneInput from "react-phone-input-2";
import MultiSelectLanguage from "./MultiSelectLanguage";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

const BookingTour = ({ onClick, location, language, data }) => {
  const [startDate, setStartDate] = useState(null);
  const [lang, setLang] = useState(null);
  const [adultQnt, setAdultQnt] = useState(
    data?.Product_Header?.no_of_pax ? data?.Product_Header?.no_of_pax : 0
  );
  const [childQnt, setChildQnt] = useState(0);
  const [infantQnt, setInfantQnt] = useState(0);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [phone, setPhone] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState("");
  const [req, setReq] = useState(null);
  const [options, setOptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tagSuccess, setTagSuccess] = useState(false);
  const [tagDanger, setTagDanger] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [groupedTimes, setGroupedTimes] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);
  const locationState = useLocation();
  const selectedDate = locationState.state?.selectedDate || null;
  const [price, setPrice] = useState(0);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [place1, setPlace1] = useState("");
  const [totalAdultPrice, setTotalAdultPrice] = useState(0);
  const [totalChildPrice, setTotalChildPrice] = useState(0);
  const [totalInfantPrice, setTotalInfantPrice] = useState(0);
  const [mAQ, setMAQ] = useState(0);
  const [mxAQ, setMxAQ] = useState(0);
  const [mCQ, setMCQ] = useState(0);
  const [mxCQ, setMxCQ] = useState(0);
  const [mIQ, setMIQ] = useState(0);
  const [mxIQ, setMxIQ] = useState(0);
  // console.log(data);
  const handlePlace1Change = (autocomplete) => {
    const place = autocomplete.getPlace();
    if (place && place.name) {
      setPlace1(place.name + ", " + place.formatted_address);
      setAddress(place.name + ", " + place.formatted_address);
      // console.log(place.name + place.formatted_address);
    }
    // console.log(autocomplete, place);
  };

  const handleLanguageChange = (selectedOptions) => {
    setSelectedLanguages(selectedOptions);
  };
  const getDayOfWeek = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[date.getDay()];
  };
  const getCurrentGroup = () => {
    const dayOfWeek = getDayOfWeek(startDate);
    return dayOfWeek === "Fri" || dayOfWeek === "Sat" || dayOfWeek === "Sun"
      ? "Fri,Sat,Sun"
      : "Mon,Tue,Wed,Thu";
  };
  const validateFields = () => {
    const errors = [];

    if (!startDate) errors.push("Date");
    if (data?.prices !== "Per Person " && !selectedTime) errors.push("Time");
    if (!phone) errors.push("Phone");
    if (!name) errors.push("Name");
    if (!email) errors.push("Email");
    if (!address) errors.push("Address");

    if (total === 0) errors.push("Pricing");

    return errors;
  };

  useEffect(() => {
    if (startDate) {
      const dayOfWeek = getDayOfWeek(startDate);
      const group =
        dayOfWeek === "Fri" || dayOfWeek === "Sat" || dayOfWeek === "Sun"
          ? "Fri,Sat,Sun"
          : "Mon,Tue,Wed,Thu";

      // Handle "per person" group type
      const filtered = data.times.filter(
        (time) => time.group_type === group || time.group_type === "per person"
      );

      // Group times by group_type, ensuring "per person" shows all days
      const grouped = filtered.reduce((acc, time) => {
        if (time.group_type === "per person") {
          if (!acc["per person"]) {
            acc["per person"] = [];
          }
          acc["per person"].push(time.pickup_time);
        } else {
          if (!acc[time.group_type]) {
            acc[time.group_type] = [];
          }
          acc[time.group_type].push(time.pickup_time);
        }
        return acc;
      }, {});

      setGroupedTimes(grouped);
    }
  }, [startDate, data.times]);

  useEffect(() => {
    // Retrieve startDate from local storage
    const storedStartDate = localStorage.getItem("startDate");
    if (storedStartDate) {
      setStartDate(storedStartDate);
    }
  }, []);
  useEffect(() => {
    if (selectedDate) {
      setStartDate(selectedDate);
    }
  }, [selectedDate]);

  const navigate = useNavigate();
  const formatDate = (date) => {
    return format(new Date(date), "dd/MM/yyyy");
  };

  const TotalPrice =
    total - data?.Product_Header?.promotion_discount_price < 0
      ? 0
      : total - data?.Product_Header?.promotion_discount_price;

  const handleSubmit = async () => {
    // const totalAdultPrice = 0;
    // const totalChildPrice = 0;
    // const totalInfantPrice = 0;

    // if (data && data?.prices && data?.prices !== "Per Person ") {
    //   const group = getCurrentGroup();
    //   const adultRanges = data?.prices?.filter(
    //     (price) => price.person_type === "Adult" && price.group_type === group
    //   );
    //   const childRanges = data?.prices?.filter(
    //     (price) => price.person_type === "Child" && price.group_type === group
    //   );
    //   const infantRanges = data?.prices?.filter(
    //     (price) => price.person_type === "Infant" && price.group_type === group
    //   );
    //   totalAdultPrice = calculatePrice(adultQnt, adultRanges);
    //   totalChildPrice = calculatePrice(childQnt, childRanges);
    //   totalInfantPrice = calculatePrice(infantQnt, infantRanges);
    // }
    const details = {
      booking_date: formatDate(startDate),
      pickup_time: selectedTime,
      user_name: name,
      no_of_pax: (
        parseInt(adultQnt) +
        parseInt(childQnt) +
        parseInt(infantQnt)
      ).toString(),
      no_of_adults: adultQnt.toString(),
      price_adult:
        data?.prices !== "Per Person "
          ? totalAdultPrice.toString()
          : data?.Product_Header?.price_adult,
      no_of_child: childQnt.toString(),
      price_child:
        data?.prices !== "Per Person "
          ? totalChildPrice.toString()
          : data?.Product_Header?.price_child,
      no_of_infant: infantQnt.toString(),
      price_infant:
        data?.prices !== "Per Person "
          ? totalInfantPrice.toString()
          : data?.Product_Header?.price_infant,
      user_phone: phone,
      user_email: email,
      pickup_address: address,
      country: country,
      tour_language: selectedLanguages.map((lang) => lang.label).join(", "),
      special_requirements: req,
      net_price: TotalPrice.toString(),
      product_id: data?.Product_Header?.id,
    };
    // console.log(details);

    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/tours/create_tour_booking.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(details),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok.");
      const apiresponse = await response.json();

      // console.log(apiresponse);
      setLoading(false);
      if (apiresponse.success) {
        setTagSuccess(
          "Thank you for submitting your Package Request, our representative will contact you via email/phone shortly !"
        );
        handleCheckAvailability(apiresponse.booking_id, details);
      }
      if (apiresponse.success) {
        setTagSuccess(
          "Thank you for submitting, our support team will contact you soon!"
        );
        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setCity("");
        setStartDate(null);
        setAdultQnt(0);
        setChildQnt(0);
        setInfantQnt(0);
      } else {
        throw new Error(apiresponse.error || "Failed to create booking.");
      }
    } catch (error) {
      console.error("Failed to create booking:", error);
      // Handle errors here (e.g., display error message)
      if (error) {
        setTagDanger("There is some issue booking your tour, please try again");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCheckAvailability = (id, bookingDetails) => {
    navigate(`/tour-booking`, {
      state: { data, bookingDetails, id, total },
    });
  };

  const calculatePrice = (quantity, ranges) => {
    if (quantity === 0) return 0;

    let price = 0;
    ranges.forEach((range) => {
      const min = parseInt(range.px_min);
      const max = parseInt(range.px_max);
      const rangePrice = parseInt(range.price);

      if (quantity >= min && quantity <= max) {
        price = rangePrice;
      }
    });
    return price;
  };

  // useEffect(() => {
  //   if (data && data?.prices && data?.prices !== "Per Person ") {
  //     const group =
  //       getDayOfWeek(startDate) === "Fri" ||
  //       getDayOfWeek(startDate) === "Sat" ||
  //       getDayOfWeek(startDate) === "Sun"
  //         ? "Fri,Sat,Sun"
  //         : "Mon,Tue,Wed,Thu";

  //     const adultRanges = data?.prices?.filter(
  //       (price) => price?.person_type === "Adult" && price?.group_type === group
  //     );
  //     const childRanges = data?.prices?.filter(
  //       (price) => price?.person_type === "Child" && price?.group_type === group
  //     );

  //     // Check if the price in the ranges is greater than 0
  //     const hasValidAdultPrice = adultRanges.some((price) => price?.amount > 0);
  //     const hasValidChildPrice = childRanges.some((price) => price?.amount > 0);

  //     if (hasValidAdultPrice || hasValidChildPrice) {
  //       setPrice(hasValidAdultPrice + hasValidChildPrice);
  //     } else {
  //       setPrice(0);
  //     }
  //   } else if (data?.prices === "Per Person ") {
  //     // Check if there are valid prices before calculating total
  //     if (
  //       data.Product_Header.price_adult > 0 ||
  //       data.Product_Header.price_child > 0
  //     ) {
  //       setPrice(
  //         parseInt(data.Product_Header.price_adult) +
  //           parseInt(data.Product_Header.price_child)
  //       );
  //     } else {
  //       setPrice(0);
  //     }
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (data && data?.prices && data?.prices !== "Per Person ") {
  //     const group =
  //       getDayOfWeek(startDate) === "Fri" ||
  //       getDayOfWeek(startDate) === "Sat" ||
  //       getDayOfWeek(startDate) === "Sun"
  //         ? "Fri,Sat,Sun"
  //         : "Mon,Tue,Wed,Thu";

  //     const adultRanges = data?.prices?.filter(
  //       (price) => price?.person_type === "Adult" && price?.group_type === group
  //     );
  //     const childRanges = data?.prices?.filter(
  //       (price) => price?.person_type === "Child" && price?.group_type === group
  //     );
  //     const infantRanges = data?.prices?.filter(
  //       (price) =>
  //         price?.person_type === "Infant" && price?.group_type === group
  //     );

  //     // Calculate prices
  //     const totalAdultPrice = calculatePrice(
  //       Math.max(adultQnt, data.Product_Header.no_of_pax),
  //       adultRanges
  //     );
  //     const totalChildPrice = calculatePrice(childQnt, childRanges);
  //     const totalInfantPrice = calculatePrice(infantQnt, infantRanges);
  //     setTotalAdultPrice(totalAdultPrice);
  //     setTotalChildPrice(totalChildPrice);
  //     setTotalInfantPrice(totalInfantPrice);
  //     const price = totalAdultPrice + totalChildPrice + totalInfantPrice;
  //     setTotal(price);

  //     // console.log(
  //     //   "tott",
  //     //   price,
  //     //   totalAdultPrice,
  //     //   totalChildPrice,
  //     //   totalInfantPrice
  //     // );
  //   } else if (data?.prices === "Per Person ") {
  //     // Check if there are valid prices before calculating total
  //     if (
  //       data.Product_Header.price_adult > 0 ||
  //       data.Product_Header.price_child > 0 ||
  //       data.Product_Header.price_infant > 0
  //     ) {
  //       const total =
  //         data.Product_Header.price_child * childQnt +
  //         data.Product_Header.price_adult *
  //           Math.max(adultQnt, data.Product_Header.no_of_pax) +
  //         data.Product_Header.price_infant * infantQnt;
  //       setTotal(total);
  //     } else {
  //       setTotal(0);
  //     }
  //   }
  // }, [adultQnt, childQnt, infantQnt, startDate, data]);

  useEffect(() => {
    if (data && data?.prices && data?.prices !== "Per Person ") {
      // Determine the group type based on the selected day of the week
      const group =
        getDayOfWeek(startDate) === "Fri" ||
        getDayOfWeek(startDate) === "Sat" ||
        getDayOfWeek(startDate) === "Sun"
          ? "Fri,Sat,Sun"
          : "Mon,Tue,Wed,Thu";

      // Get the ranges for adults, children, and infants based on the selected date's group
      const adultRanges = data?.prices?.filter(
        (price) => price?.person_type === "Adult" && price?.group_type === group
      );
      const childRanges = data?.prices?.filter(
        (price) => price?.person_type === "Child" && price?.group_type === group
      );
      const infantRanges = data?.prices?.filter(
        (price) =>
          price?.person_type === "Infant" && price?.group_type === group
      );

      // Calculate min and max quantities for adults, children, and infants based on the selected date
      const minAdultQnt = Math.min(
        ...adultRanges.map((r) => parseInt(r.px_min))
      );
      setMAQ(minAdultQnt);
      const maxAdultQnt = Math.max(
        ...adultRanges.map((r) => parseInt(r.px_max))
      );
      setMxAQ(maxAdultQnt);

      const minChildQnt =
        childRanges.length > 0
          ? Math.min(...childRanges.map((r) => parseInt(r.px_min)))
          : 0;
      setMCQ(minChildQnt);
      const maxChildQnt =
        childRanges.length > 0
          ? Math.max(...childRanges.map((r) => parseInt(r.px_max)))
          : 0;
      setMxCQ(maxChildQnt);
      const minInfantQnt =
        infantRanges.length > 0
          ? Math.min(...infantRanges.map((r) => parseInt(r.px_min)))
          : 0;
      setMIQ(minInfantQnt);
      const maxInfantQnt =
        infantRanges.length > 0
          ? Math.max(...infantRanges.map((r) => parseInt(r.px_max)))
          : 0;
      setMxIQ(maxInfantQnt);

      // Ensure the counters do not exceed the max values or go below the min values based on the new date
      setAdultQnt((prev) => Math.max(minAdultQnt, Math.min(prev, maxAdultQnt)));
      setChildQnt((prev) => Math.max(minChildQnt, Math.min(prev, maxChildQnt)));
      setInfantQnt((prev) =>
        Math.max(minInfantQnt, Math.min(prev, maxInfantQnt))
      );

      // Calculate prices based on the new counters
      const totalAdultPrice = calculatePrice(adultQnt, adultRanges);
      const totalChildPrice = calculatePrice(childQnt, childRanges);
      const totalInfantPrice = calculatePrice(infantQnt, infantRanges);

      // Set the calculated prices
      setTotalAdultPrice(totalAdultPrice);
      setTotalChildPrice(totalChildPrice);
      setTotalInfantPrice(totalInfantPrice);

      // Calculate the total price
      const total = totalAdultPrice + totalChildPrice + totalInfantPrice;
      setTotal(total);
    } else if (data?.prices === "Per Person ") {
      // Handle "Per Person" pricing logic
      if (
        data.Product_Header.price_adult > 0 ||
        data.Product_Header.price_child > 0 ||
        data.Product_Header.price_infant > 0
      ) {
        const total =
          data.Product_Header.price_child * childQnt +
          data.Product_Header.price_adult *
            Math.max(adultQnt, data.Product_Header.no_of_pax) +
          data.Product_Header.price_infant * infantQnt;
        setTotal(total);
      } else {
        setTotal(0);
      }
    }
  }, [adultQnt, childQnt, infantQnt, startDate, data]);

  useEffect(() => {
    if (data && Array.isArray(data?.cities)) {
      const options = data?.cities?.map((item) => ({
        value: item.city_id, // Assuming 'city_name' is the unique identifier for each city
        label: item.city_name, // The display text you want to show for each option
      }));
      setOptions(options);
      // console.log(options);
    }
  }, [data]);

  // console.log(data.cities, city, name, email, startDate, phone);
  // console.log(loading);
  return (
    <>
      <div className="bg-[#F9F9F7] px-3 py-6 rounded-lg">
        <div className="flex items-center gap-2 border-b mb-3 pb-6">
          <img
            src="/assets/icons/location-2.png"
            alt=""
            className="flex-none w-9 h-9 object-contain"
          />
          <h1 className="text-lg font-semibold">Booking Your Tour</h1>
        </div>
        <div className="flex items-center justify-between gap-2 border-b mb-3 pb-3">
          <h2 className="font-semibold">Date</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <img
              className="w-6 object-contain h-6"
              src="/assets/icons/calendar.png"
              alt="calendar"
            />
            <DatePicker
              required
              disabled={loading}
              locale={"au"}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              className="w-full"
            />
          </div>
        </div>
        <>
          <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
            <h2 className="font-semibold">Time</h2>
            <div className="flex w-[70%] flex-col items-start gap-2">
              {Object.keys(groupedTimes).map((group) => (
                <div key={group} className="mb-4">
                  <h3 className="font-semibold mb-3">
                    {group !== "per person" ? group : ""}
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {groupedTimes[group].map((time, index) => (
                      <button
                        required
                        disabled={loading}
                        key={index}
                        onClick={() =>
                          setSelectedTime((prevSelectedTime) =>
                            prevSelectedTime === time ? null : time
                          )
                        }
                        className={`btn flex items-center gap-1 border-[#63AB45] border-2 p-1 px-3 rounded-md ${
                          selectedTime === time
                            ? "btn-primary bg-primary text-white"
                            : "btn-secondary"
                        }`}
                      >
                        {selectedTime === time && (
                          <BiSolidBadgeCheck
                            color={
                              selectedTime === time ? "#ffffff" : "#63AB45"
                            }
                          />
                        )}
                        {time}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>

        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Name</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <input
              required
              disabled={loading}
              type="text"
              placeholder="Enter Your Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Phone</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <PhoneInput
              required
              country={"au"} // You can set the default country code here
              value={phone}
              onChange={(phone) => setPhone(phone)}
              inputStyle={{
                width: "100%",
                border: "none",
                outline: "none",
              }}
              buttonStyle={{
                border: "none",
                outline: "none",
              }}
              disabled={loading}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Email</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <input
              required
              disabled={loading}
              type="email"
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        {/* <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Pickup Address</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <input
              disabled={loading}
              type="text"
              placeholder="Enter Your Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div> */}
        <LoadScript
          googleMapsApiKey="AIzaSyDSS70hvcoWwGoJWnYfsNM8eOTzdybk-rg"
          libraries={["places"]}
        >
          <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
            <h2 className="font-semibold">Pickup Address</h2>

            <Autocomplete
              onLoad={(autocomplete) => {
                autocomplete.addListener("place_changed", () => {
                  handlePlace1Change(autocomplete);
                });
              }}
            >
              <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
                <input
                  name="pickupLocation"
                  type="text"
                  placeholder="Insert Pickup Location"
                  onChange={(e) => setPlace1(e.target.value)} // Only update place1, not address
                  value={place1 || ""}
                />
              </div>
            </Autocomplete>
          </div>
        </LoadScript>

        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Country</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <select
              disabled={loading}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="w-full"
              placeholder="Select Country"
            >
              <option value="" disabled>
                Select Country
              </option>
              {countries.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Phone</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <input
              required
              disabled={loading}
              type="tel"
              placeholder="Enter Your Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div> */}

        <div className="flex flex-col items-start justify-start gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Tour Language</h2>
          <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
            <MultiSelectLanguage
              languages={data.languages}
              onChange={handleLanguageChange}
            />
          </div>
        </div>

        <div className="flex flex-col items-start justify-start gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Remarks</h2>
          <div className="flex w-[100%] items-center gap-2 border rounded p-2 bg-white">
            <textarea
              className="w-[100%]"
              disabled={loading}
              type="text"
              placeholder="Enter your Special Requirement like age of kid’s, dietary requirements, any assistance, etc"
              value={req}
              rows={4}
              onChange={(e) => setReq(e.target.value)}
            />
          </div>
        </div>
        <h1 className="text-lg font-semibold border-b pb-3 mb-3">Pricing</h1>
        <div className="flex items-center justify-between gap-2 border-b mb-3 pb-3">
          <div className="flex items-center gap-1">
            <h2 className="font-semibold">Adult</h2>
            <span className="text-[#A5A5A5]">(12+ years) </span>
          </div>
          <div className="flex w-max items-center gap-2 border rounded p-2 bg-white">
            <img
              className="w-6 object-contain h-6"
              src="/assets/icons/user.png"
              alt="calendar"
            />
            <input
              onChange={(e) => setAdultQnt(e.target.value)}
              type="number"
              disabled={loading}
              placeholder="0"
              value={adultQnt}
              className="w-[24px] number-input focus:outline-none"
            />
            <div className="flex items-center gap-1">
              <button
                disabled={loading || adultQnt === mAQ}
                onClick={() =>
                  setAdultQnt(adultQnt > 0 ? parseInt(adultQnt) - 1 : 0)
                }
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniMinusSmall />
              </button>
              <button
                disabled={loading || adultQnt === mxAQ}
                onClick={() => setAdultQnt(parseInt(adultQnt) + 1)}
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniPlusSmall />
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 border-b mb-3 pb-3">
          <div className="flex items-center gap-1">
            <h2 className="font-semibold">Child</h2>
            <span className="text-[#A5A5A5]">(2-12 years)</span>
          </div>
          <div className="flex w-max items-center gap-2 border rounded p-2 bg-white">
            <img
              className="w-6 object-contain h-6"
              src="/assets/icons/user.png"
              alt="calendar"
            />
            <input
              onChange={(e) => setChildQnt(e.target.value)}
              type="number"
              disabled={loading}
              placeholder="0"
              value={childQnt}
              className="w-[24px] number-input focus:outline-none"
            />
            <div className="flex items-center gap-1">
              <button
                disabled={loading || childQnt === mCQ}
                onClick={() =>
                  setChildQnt(childQnt > 0 ? parseInt(childQnt) - 1 : 0)
                }
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniMinusSmall />
              </button>
              <button
                disabled={loading || childQnt === mxCQ}
                onClick={() => setChildQnt(parseInt(childQnt) + 1)}
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniPlusSmall />
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <div className="flex items-center gap-1">
            <h2 className="font-semibold">Infant</h2>
            <span className="text-[#A5A5A5]">(0-2 years)</span>
          </div>
          <div className="flex w-max items-center gap-2 border rounded p-2 bg-white">
            <img
              className="w-6 object-contain h-6"
              src="/assets/icons/user.png"
              alt="calendar"
            />
            <input
              onChange={(e) => setInfantQnt(e.target.value)}
              type="number"
              disabled={loading}
              placeholder="0"
              value={infantQnt}
              className="w-[24px] number-input focus:outline-none"
            />
            <div className="flex items-center gap-1">
              <button
                disabled={loading || infantQnt === mIQ}
                onClick={() =>
                  setInfantQnt(infantQnt > 0 ? parseInt(infantQnt) - 1 : 0)
                }
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniMinusSmall />
              </button>
              <button
                disabled={loading || infantQnt === mxIQ}
                onClick={() => setInfantQnt(parseInt(infantQnt) + 1)}
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniPlusSmall />
              </button>
            </div>
          </div>
        </div>
        {/* <h1 className="text-lg font-semibold border-b pb-3 mb-3">
          Additional Services
        </h1> */}
        {/* <div className="flex items-center justify-between gap-2 border-b mb-3 pb-3">
          <div className="flex items-center gap-1">
            <FaCircleCheck color="#63AB45" />
            <h2 className="font-medium">Service Per Booking</h2>
          </div>
          <p className="font-bold text-lg">$50</p>
        </div> */}
        {/* <div className="flex items-center justify-between gap-2 border-b mb-3 pb-3">
          <div className="flex items-center gap-1">
            <FaCircleCheck color="#63AB45" />
            <h2 className="font-medium">Service Per Person</h2>
          </div>
          <p className="font-bold text-lg">$20</p>
        </div> */}
        <div className="flex items-center justify-between gap-2 mb-3">
          <p className="font-bold text-lg">SubTotal Cost</p>
          <p className="font-bold text-lg">A${total}</p>
        </div>
        {data?.Product_Header?.promotion_discount_price <= 0 ? (
          <></>
        ) : (
          <div className="flex items-center justify-between gap-2 mb-3">
            <p className="font-bold text-lg">Promotional Discount</p>
            <p className="font-bold text-lg">
              - A${data?.Product_Header?.promotion_discount_price}
            </p>
          </div>
        )}

        <div className="flex items-center justify-between gap-2 mb-3">
          <p className="font-bold text-lg">Total Cost</p>
          <p className="font-bold text-lg">
            A$
            {TotalPrice}
          </p>
        </div>
        <div className="flex items-center gap-2 mb-6 pb-3">
          <input
            id="terms"
            type="checkbox"
            disabled={loading}
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          <label htmlFor="terms" className="font-semibold">
            I accept the
            <Link
              to="https://packages.flymiles.com.au/terms-of-use"
              className="text-blue-400"
            >
              {" "}
              Terms & Conditions
            </Link>
          </label>
        </div>

        <div className="flex justify-end mb-3">
          <Button
            disabled={total <= 0 ? true : false}
            text={loading ? "Booking..." : `Book Now`}
            onClick={(e) => {
              e.preventDefault();
              if (termsAccepted) {
                const errors = validateFields();
                if (errors.length === 0) {
                  setLoading(true);
                  handleSubmit();
                } else {
                  setTagDanger(
                    `Please fill in the following required fields: ${errors.join(
                      ", "
                    )}`
                  );
                }
              } else {
                setTagDanger(
                  "Please accept the Terms & Conditions to proceed."
                );
              }
            }}
          />
        </div>

        {tagSuccess ? (
          <div className={"flex justify-center"}>
            <div className="bg-primary_2 w-max font-medium text-primary py-2 px-5 rounded-md">
              {tagSuccess}
            </div>
          </div>
        ) : tagDanger ? (
          <div className={"flex justify-center"}>
            <div className="bg-red-100 w-max font-medium text-red-400 py-2 px-5 rounded-md">
              {tagDanger}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {/* tour information */}
      {/* <div className="border px-3 py-6 rounded-lg mt-6">
        <div className="flex items-center gap-2 border-b mb-3 pb-6">
          <img
            src="/assets/icons/location-2.png"
            alt=""
            className="flex-none w-9 h-9 object-contain"
          />
          <h1 className="text-lg font-semibold">Tour Information</h1>
        </div>
        {tourInformation.map((x, index) => (
          <div
            key={index}
            className={`flex items-center justify-between gap-2 border-b mb-3 pb-3 ${
              index === tourInformation.length - 1 ? "border-b-0 mb-0 pb-0" : ""
            } `}
          >
            <div className="flex items-center gap-1">
              <FaRegUserCircle color="#63AB45" />
              <h2 className="font-medium">{x.title}</h2>
            </div>
            <p className="font-bold text-lg">{x.value}</p>
          </div>
        ))}
      </div> */}

      {/* last minutes deal */}
      {/* <div className="border px-3 py-6 rounded-lg mt-6">
        <div className="flex items-center gap-2 border-b mb-3 pb-6">
          <img
            src="/assets/icons/location-2.png"
            alt=""
            className="flex-none w-9 h-9 object-contain"
          />
          <h1 className="text-lg font-semibold">Last Minute Deals</h1>
        </div>
        {lastMinutesDeals.map((x, index) => (
          <div
            key={index}
            className={`flex items-center gap-3 border-b mb-3 pb-3 ${
              index === lastMinutesDeals.length - 1
                ? "border-b-0 mb-0 pb-0"
                : ""
            } `}
          >
            <img
              src={x.imgUrl}
              alt="img"
              className="w-[30%] h-[100px] object-cover rounded-lg"
            />
            <div className="flex flex-col gap-2">
              <RatingStars star={5} />
              <Link
                className="font-semibold text-lg hover:text-primary transition"
                to={"/"}
              >
                {x.title}
              </Link>
              <p>
                From{" "}
                <span className="font-medium text-primary">${x.price}</span>
              </p>
            </div>
          </div>
        ))}
      </div> */}

      {/* extra features */}
      {/* {extraFeature.map((x, index) => (
        <div key={index} className="relative mt-6">
          <img
            className="h-[40vh] w-full rounded-lg object-cover"
            src={x.imgUrl}
            alt=""
          />
          <div className="absolute w-full h-full bg-black opacity-50 left-0 top-0 rounded-lg"></div>
          <div className="absolute bottom-6 left-6">
            <h1 className="font-semibold text-xl text-white mb-2">{x.title}</h1>
            <div className="flex items-center text-lg text-white gap-2">
              <MdLocationOn color="#63AB45" />
              <p>{x.location}</p>
            </div>
          </div>
        </div>
      ))} */}
    </>
  );
};

export default BookingTour;
