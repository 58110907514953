import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Tag from "../Component/Tag";
import SectionHeading from "../Component/SectionHeading";
import TourPlaceCard from "../Component/TourPlaceCard";
import { useLocation, useNavigate } from "react-router-dom";

const tourPlaceInfos = [
  {
    cardImg: "/assets/home/tour-place-1.png",
    rating: 4.9,
    featured: true,
    off: false,
    title: "boathouse Neighborhood",
    place: "North Province, Maladives",
    price: 93.65,
    day: "05",
    people: 25,
    exploreBtnLink: "/",
  },
  {
    cardImg: "/assets/home/tour-place-2.png",
    rating: 4.9,
    featured: true,
    off: 10,
    title: "boathouse Neighborhood",
    place: "North Province, Maladives",
    price: 93.65,
    day: "05",
    people: 25,
    exploreBtnLink: "/",
  },
  {
    cardImg: "/assets/home/tour-place-3.png",
    rating: 4.9,
    featured: true,
    off: false,
    title: "boathouse Neighborhood",
    place: "North Province, Maladives",
    price: 93.65,
    day: "05",
    people: 25,
    exploreBtnLink: "/",
  },
];

const PopularPlace = () => {
  const [popularTours, setPopularTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noToursAvailable, setNoToursAvailable] = useState(false);
  const navigate = useNavigate();

  const handleCheckAvaility = (id, tourData) => {
    // console.log(tourData);
    navigate(`/tour-search/${id}`, {
      state: { tourData: tourData },
    });
    // console.log(tourData);
  };
  // console.log(popularTours);
  const fetchPopularTours = async () => {
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/tours/get_popular_tours.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ popular_tours: "yes" }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.length === 0) {
          setNoToursAvailable(true); // No tours available
        } else {
          setPopularTours(result); // Tours available
        }
      } else {
        setNoToursAvailable(true); // Error or no response
      }
    } catch (error) {
      console.error("Error fetching popular tours:", error);
      setNoToursAvailable(true);
    } finally {
      setLoading(false); // Loading complete
    }
  };

  useEffect(() => {
    fetchPopularTours();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="container mx-auto py-12 px-3">
      <div className="column-12 mb-9">
        <Tag
          center
          text={
            loading
              ? "Loading Popular Tours..."
              : noToursAvailable
              ? "No Popular Tours Available"
              : "Most Popular Tours"
          }
        />
      </div>
      <div className="column-12 mb-12">
        <SectionHeading
          center
          text={"Visit & enjoy adventure life with full of dreams"}
        />
      </div>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        arrows={false}
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={3000}
        infinite
      >
        {popularTours.map((x, index) => (
          <div key={index}>
            <TourPlaceCard
              cardImg={x.mainImage}
              featured={x.featured}
              off={x.off}
              rating={"5"}
              title={x.Product_Header.product_name}
              priceData={
                x.Product_Header.product_pricing !== "Per Person"
                  ? x.prices
                  : x.Product_Header.price_adult
              }
              place={x.cities}
              day={x.Product_Header.durations}
              people={x.Product_Header.no_of_pax}
              exploreBtnLink={x.Product_Header.id}
              data={x}
              onClick={(id) => handleCheckAvaility(id, x)}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default PopularPlace;
