import React, { useState, useEffect } from "react";
import HeroBanner from "../Sections/HeroBanner";
import TourSearch from "../Component/TourSearch";
import Tag from "../Component/Tag";
import TourFilter from "../Component/TourFilter";
import TourPagination from "../Component/TourPagination";
import TourSingleResult from "../Component/TourSingleResult";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoAdd } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

const tourBottomImgs = [
  "/assets/search/1.png",
  "/assets/search/2.png",
  "/assets/search/3.png",
  "/assets/search/4.png",
  "/assets/search/5.png",
  "/assets/search/3.png",
  "/assets/search/4.png",
];

const TourSearchResult = () => {
  const itemsPerPage = 10; // Number of items per page

  const [lightboxImage, setLightboxImage] = useState(null);

  const navigate = useNavigate();
  const handleCheckAvaility = (e, tourData) => {
    // console.log(tourData);
    navigate(`/tour-search/${tourData.Product_Header.id}`, {
      state: { tourData },
    });
  };
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // Access the state data passed through navigation
  const data = location?.state?.data;

  const [filteredData, setFilteredData] = useState(data || []); // Initialize with initial data or empty array

  const handleFilterChange = (filteredProducts) => {
    setFilteredData(filteredProducts);
  };

  const handleLoading = (loading) => {
    setLoading(loading);
    // console.log(loading);
  };
  // Introduce resetFilters state
  const [resetFilters, setResetFilters] = useState(0);

  useEffect(() => {
    if (data) {
      setLoading(false);
      setFilteredData(data); // Set the initial data as the filtered data
      setResetFilters((prev) => prev + 1); // Increment to signal a reset
    }
  }, [data]);
  // console.log(loading);

  // Format tour data for pagination based on filteredData
  const formattedData = Array.isArray(filteredData)
    ? filteredData.map((tour, index) => (
        <div key={index}>
          <TourSingleResult
            id={tour.Product_Header.id}
            imgUrl={`https://login.flymiles.com.au/api_sections/tours/tour_img/${tour.mainImage.map(
              (img) => img.image
            )}`}
            title={tour.Product_Header.product_name}
            // star={tour.star}
            location={tour.cities}
            // locationCountry={tour.locationCountry}
            // language={tour.language}
            prices={tour.prices}
            priceSingle={tour.Product_Header.price_adult}
            duration={tour.Product_Header.durations}
            includesHighlight={tour.inclusions}
            // tourType={tour.tourType}
            onClick={(id) => handleCheckAvaility(id, tour)}
          />
        </div>
      ))
    : [];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1023, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
    },
    sm: {
      breakpoint: { max: 463, min: 0 },
      items: 1,
    },
  };

  const openLightbox = (imageUrl) => {
    setLightboxImage(imageUrl);
  };

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  return (
    <>
      <div className="hidden lg:block">
        <HeroBanner
          text={"Search"}
          imgUrl={"/assets/contact/hero-banner.png"}
        />
      </div>
      <div className="flex justify-center w-full lg:absolute pt-[100px] pb-12 lg:py-0 lg:-mt-12">
        <TourSearch resultPage onChange={handleLoading} />
      </div>
      <div className="container mx-auto lg:pt-20 lg:pb-8">
        <div className="mx-3">
          {loading ? (
            <Tag text={"Loading..."} center />
          ) : data?.error === "No Prodcut Found" ? (
            <Tag text={"No Search Result"} center />
          ) : data?.length > 0 ? (
            <Tag text={"Your Search Result"} center />
          ) : (
            <Tag text={"No Search Result"} center />
          )}

          <div className="mt-12 lg:flex gap-6 relative">
            <div className="lg:w-1/3 absolute lg:relative right-0">
              <TourFilter
                data={data}
                onChange={handleFilterChange}
                resetFilters={resetFilters} // Pass resetFilters prop
              />
            </div>
            <div className="lg:w-2/3">
              <TourPagination
                data={formattedData}
                itemsPerPage={itemsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-6 -mb-24">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          arrows={false}
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite
        >
          {tourBottomImgs.map((imageUrl, index) => (
            <div className="relative group" key={index}>
              <img
                className="rounded-xl px-2 w-full"
                src={imageUrl}
                alt="" // Open lightbox on click
              />
              <div
                onClick={() => openLightbox(imageUrl)}
                className="hidden absolute h-12 w-12 bg-[#F7921E] z-10 text-white top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2 group-hover:flex items-center justify-center rounded-full cursor-pointer"
              >
                <IoAdd />
              </div>
              <div className="hidden group-hover:block bg-black bg-opacity-75 h-full w-[calc(100%-16px)] ms-2 rounded-lg absolute top-0 left-0"></div>
            </div>
          ))}
        </Carousel>
      </div>
      {lightboxImage && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center z-50 items-center"
          onClick={closeLightbox}
        >
          <img
            src={lightboxImage}
            alt="Lightbox"
            className="max-w-full w-[80vw] h-[80vh] object-cover object-center max-h-full" // Close lightbox on click
          />
        </div>
      )}
      <div className="bg-secondary h-24"></div>
    </>
  );
};

export default TourSearchResult;
