import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import cn from "classnames";
import TourSearch from "../Component/TourSearch";
import TransfersSearch from "../Component/TransfersSearch";
import PackageSearch from "../Component/PackageSearch";

const homeHeroImgs = [
  "/assets/home/hero-1.jpg",
  "/assets/home/hero-2.jpg",
  "/assets/home/hero-3.jpg",
  "/assets/home/hero-4.jpg",
  "/assets/home/hero-5.jpg",
  "/assets/home/hero-6.jpg",
  "/assets/home/hero-7.jpg",
  "/assets/home/hero-8.jpg",
];

const HomeHero = () => {
  const [selectedCategory, setSelectedCategory] = useState("Tours");
  const [isPackageEnabled, setIsPackageEnabled] = useState(true);

  const handleCategoryChange = (category) => {
    if (category === "Package" && !isPackageEnabled) return;

    setSelectedCategory(category);
  };

  useEffect(() => {
    const fetchPackageSetting = async () => {
      try {
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/packages/packages_settings.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ package_setting: "all" }),
          }
        );
        const result = await response.json();
        // console.log(result.view);
        setIsPackageEnabled(Number(result.view) !== 0); // Convert view to a number before checking
      } catch (error) {
        console.error("Error fetching package setting:", error);
      }
    };

    fetchPackageSetting();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      style={{
        backgroundImage: "url('/assets/home/hero-bg.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container px-3 mx-auto pt-[135px]">
        <div className="flex items-center gap-6">
          <div className="w-full lg:w-4/6">
            <div className="flex w-full max-w-[714px] p-3 bg-white shadow-lg rounded-lg mx-auto border-green-100">
              <button
                onClick={() => handleCategoryChange("Tours")}
                className={cn(
                  "uppercase font-semibold rounded p-1 flex h-10 justify-center items-center w-full",
                  [
                    selectedCategory === "Tours"
                      ? "bg-primary text-white"
                      : "bg-transparent text-secondary",
                  ]
                )}
              >
                Tours
              </button>
              <button
                onClick={() => handleCategoryChange("Transfers")}
                className={cn(
                  "uppercase font-semibold rounded p-1 flex h-10 justify-center items-center w-full",
                  [
                    selectedCategory === "Transfers"
                      ? "bg-primary text-white"
                      : "bg-transparent text-secondary",
                  ]
                )}
              >
                Transfers
              </button>
              <button
                onClick={() => handleCategoryChange("Package")}
                className={cn(
                  "uppercase font-semibold rounded p-1 flex h-10 justify-center items-center w-full",
                  [
                    selectedCategory === "Package" && isPackageEnabled
                      ? "bg-primary text-white"
                      : "bg-transparent text-secondary opacity-40",
                  ]
                )}
                disabled={!isPackageEnabled}
              >
                Package
              </button>
            </div>
            <div className="flex w-full max-w-[714px] p-6 bg-white shadow-lg rounded-lg mt-6 mx-auto">
              {selectedCategory === "Tours" ? (
                <TourSearch />
              ) : selectedCategory === "Transfers" ? (
                <TransfersSearch />
              ) : selectedCategory === "Package" ? (
                <PackageSearch />
              ) : null}
            </div>
          </div>
          <div className="hidden lg:block lg:w-2/6">
            <div className="text-center">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                arrows={false}
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={3000}
                infinite
              >
                {homeHeroImgs.map((x, index) => (
                  <img
                    key={index}
                    className="w-full lg:h-[370px] xl:h-[470px] 2xl:h-[570px] object-cover rounded-tl-full rounded-tr-full max-w-[691px]"
                    src={x}
                    alt=""
                  />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
