import React, { useState } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

const TourPagination = ({ data, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
        <h2 className="font-semibold mb-8">Showing Results{indexOfFirstItem + 1} to {indexOfFirstItem + currentItems.length} Of {data.length}</h2>
      {currentItems.map((item, index) => (
        <div key={index}>{item}</div>
      ))}

      <div className="flex justify-center gap-2">
        <button className="w-9 h-9 border rounded-full flex justify-center items-center" onClick={prevPage} disabled={currentPage === 1}>
        <BsArrowLeftShort size={22} />
        </button>
        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active bg-primary text-white font-medium w-9 h-9 border rounded-full flex justify-center items-center" : "w-9 h-9 border rounded-full flex justify-center font-medium items-center"}
          >
            {index + 1}
          </button>
        ))}
        <button className="w-9 h-9 border rounded-full flex justify-center items-center" onClick={nextPage} disabled={currentPage === totalPages}>
        <BsArrowRightShort />
        </button>
      </div>
    </div>
  );
};

export default TourPagination;
