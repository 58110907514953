import React, { useState } from "react";
import HeroBanner from "../Sections/HeroBanner";
import Tag from "../Component/Tag";
import SectionHeading from "../Component/SectionHeading";
import cn from "classnames";
import SydneyContact from "../Sections/SydneyContact";
import IndiaContact from "../Sections/IndiaContact";

const ContactUs = () => {
  const [selectedCategory, setSelectedCategory] = useState("Sydney Office");
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };
  return (
    <>
      <HeroBanner
        text={"Contact Us"}
        imgUrl={"/assets/contact/hero-banner.png"}
      />
      <div className="container mx-auto py-12">
        <div className="mx-3">
          <Tag center text={"Contact Us"} />
          <SectionHeading
            mt={6}
            maxW={600}
            center
            text={
              "Ready to Get Our Best Services! Feel Free to Contact With Us"
            }
          />
          <div className="flex w-full max-w-[350px] p-1 bg-white shadow-lg rounded-lg mx-auto mt-6 border border-green-100">
            <button
              onClick={() => handleCategoryChange("Sydney Office")}
              className={cn(
                "uppercase font-semibold rounded p-1 flex h-10 justify-center items-center w-full",
                [
                  selectedCategory === "Sydney Office"
                    ? "bg-primary text-white"
                    : "bg-transparent text-secondary",
                ]
              )}
            >
              Sydney Office
            </button>
            <button
              onClick={() => handleCategoryChange("India Office")}
              className={cn(
                "uppercase font-semibold rounded p-1 flex h-10 justify-center items-center w-full",
                [
                  selectedCategory === "India Office"
                    ? "bg-primary text-white"
                    : "bg-transparent text-secondary",
                ]
              )}
            >
              India Office
            </button>
          </div>
        </div>
        {selectedCategory === "Sydney Office" ? (
          <SydneyContact />
        ) : selectedCategory === "India Office" ? (
          <IndiaContact />
        ) : null}
      </div>
    </>
  );
};

export default ContactUs;
