import React, { useState, useEffect } from "react";
import { IoFilterOutline } from "react-icons/io5";

const TourFilter = ({ data, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [aminities, setAminities] = useState({
    private: { title: "Private", checked: false },
    shared: { title: "Shared", checked: false },
  });
  const [tourCategories, setTourCategories] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    // Fetch tour categories from the API
    const fetchTourCategories = async () => {
      try {
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/tours/get_tours_categories_front_End.php"
        );
        const categories = await response.json();

        // Convert the fetched categories to the required format
        const formattedCategories = categories.reduce((acc, category) => {
          acc[category.name.toLowerCase().replace(/\s+/g, "")] = {
            title: category.name,
            checked: false,
          };
          return acc;
        }, {});

        setTourCategories(formattedCategories);
      } catch (error) {
        console.error("Error fetching tour categories:", error);
      }
    };

    fetchTourCategories();
  }, [data]);

  useEffect(() => {
    filterProducts(); // Trigger filtering whenever the categories or amenities change
  }, [tourCategories, aminities]);

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  const handleAminitiesChange = (event) => {
    const { name, checked } = event.target;
    setAminities((prev) => ({
      ...prev,
      [name]: { ...prev[name], checked },
    }));
  };

  const handletourCategoriesChange = (event) => {
    const { name, checked } = event.target;
    setTourCategories((prev) => ({
      ...prev,
      [name]: { ...prev[name], checked },
    }));
  };

  const filterProducts = () => {
    const selectedCategories = Object.entries(tourCategories)
      .filter(([_, category]) => category.checked)
      .map(([_, category]) => category.title);

    const selectedAminities = Object.entries(aminities)
      .filter(([_, amenity]) => amenity.checked)
      .map(([_, amenity]) => amenity.title.toLowerCase());

    if (Array.isArray(data)) {
      const filtered = data.filter((product) => {
        // Check if the product's inclusions match any of the selected categories
        const productCategories = product.inclusions.map((inc) =>
          inc.name.trim()
        );

        const categoryMatch = selectedCategories.some((category) =>
          productCategories.includes(category)
        );

        // Check if the product's tour type matches any selected amenities (if any)
        const tourTypeMatch =
          selectedAminities.length === 0 ||
          selectedAminities.includes(
            product.Product_Header.tour_type?.toLowerCase()
          );

        // Return the product if it matches either category, tour type, or both
        if (selectedCategories.length > 0 && selectedAminities.length > 0) {
          return categoryMatch && tourTypeMatch; // Filter by both category and tour type
        } else if (selectedCategories.length > 0) {
          return categoryMatch; // Filter by category only
        } else if (selectedAminities.length > 0) {
          return tourTypeMatch; // Filter by tour type only
        } else {
          return true; // If no filters are selected, return all products
        }
      });

      setFilteredProducts(filtered || []); // Update the filtered products state
      onChange(filtered || []); // Pass the filtered products back to the parent component
    } else {
      setFilteredProducts([]); // If data is not an array, set filtered products to an empty array
      onChange([]); // Pass an empty array to the parent component
    }
  };

  return (
    <div className="relative lg:inline-block lg:relative">
      <button
        className="flex -mt-[9px] items-center gap-1 border rounded-full py-2 px-4 lg:hidden"
        onClick={toggleFilter}
      >
        <IoFilterOutline />
        <p>Filter</p>
      </button>
      <div
        onClick={toggleFilter}
        className={`block lg:hidden fixed h-lvh w-lvw bg-[#00000090] z-50 top-0 ${
          isOpen ? "left-0" : "-left-full"
        }`}
      ></div>
      <div
        className={`fixed top-0 left-0 h-lvh lg:h-max z-50 lg:relative bg-white border border-gray-300 p-4 lg:mt-2 w-80 ${
          isOpen ? "block" : "hidden"
        } lg:block`}
      >
        <div className="mb-6 z-20">
          <h3 className="font-bold mb-3">Tour Type</h3>
          <ul>
            {Object.entries(aminities).map(([name, { title, checked }]) => (
              <li key={name} className="mb-2 flex justify-between items-center">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name={name}
                    id={name}
                    checked={checked}
                    onChange={handleAminitiesChange}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label htmlFor={name} className="ml-2">
                    {title}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="font-bold mb-3">Tour Categories</h3>
          <ul>
            {Object.entries(tourCategories).map(
              ([name, { title, checked }]) => (
                <li
                  key={name}
                  className="mb-2 flex justify-between items-center"
                >
                  <div className="flex items-center">
                    <input
                      className="form-checkbox h-6 w-6 text-indigo-600 border-indigo-600 rounded-md"
                      type="checkbox"
                      name={name}
                      id={name}
                      checked={checked}
                      onChange={handletourCategoriesChange}
                      style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "yellow",
                      }}
                    />
                    <label htmlFor={name} className="ml-2">
                      {title}
                    </label>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TourFilter;
