import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import packageJson from "../mocks/packageResult.json";
import tourJson from "../mocks/tourResult.json";
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchTerm(
      props.selectProps.inputValue || props.getValue()[0]?.value || ""
    );
  }, [props.selectProps.inputValue, props.getValue]);

  return (
    <ValueContainer {...props}>
      {searchTerm ? null : (
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
      )}
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const CityDropdown = ({
  packageSearch,
  tourSearch,
  onSelectCity,
  initialCityId,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null); // State to manage selected city

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/packages/get_country_city_list.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ get_city: "yes" }),
          }
        );
        const data = await response.json();
        // console.log(data);
        const transformedOptions = data.map((city) => ({
          value: city.id, // Adjust depending on your actual API response structure
          label: city.city, // Adjust depending on your actual API response structure
        }));

        setOptions(transformedOptions);
      } catch (error) {
        console.error("Failed to fetch cities:", error);
      }
    };
    const fetchToursCities = async () => {
      try {
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/tours/get_country_city_list.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ get_city: "yes" }),
          }
        );
        const data = await response.json();
        // console.log(data);
        const transformedOptions = data.map((city) => ({
          value: city.id, // Adjust depending on your actual API response structure
          label: city.city, // Adjust depending on your actual API response structure
        }));

        setOptions(transformedOptions);
      } catch (error) {
        console.error("Failed to fetch cities:", error);
      }
    };

    if (packageSearch) {
      fetchCities();
    }
    if (tourSearch) {
      fetchToursCities();
    }

    // if (packageSearch) {
    //   const transformedOptions = packageJson.map((city) => ({
    //     value: city.locationCity,
    //     label: city.locationCity,
    //   }));
    //   setOptions(transformedOptions);
    // }

    if (tourSearch) {
      const transformedOptions = tourJson.map((city) => ({
        value: city.location,
        label: city.location,
      }));
      setOptions(transformedOptions);
    }
  }, []);

  useEffect(() => {
    // Set initial value if initialCityId is provided

    if (initialCityId) {
      const selectedOption = options.find(
        (option) => option.value === initialCityId
      );
      if (selectedOption) {
        setSelectedCity(selectedOption);
        onSelectCity(selectedOption.value);
      }
    }
  }, [initialCityId, options, onSelectCity]);

  const handleChange = (selectedOption) => {
    setSearchTerm(selectedOption.label);
    setSelectedCity(selectedOption);
    onSelectCity(selectedOption.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "24px",
      "&:hover": {
        boxShadow: "none",
      },
      border: "none !important",
      borderColor: "transparent",
      boxShadow: "none !important",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "18px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#f7921e" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  return (
    <div>
      <Select
        components={{ ValueContainer: CustomValueContainer }}
        options={options}
        isSearchable
        placeholder={""}
        onChange={handleChange}
        value={selectedCity}
        styles={customStyles}
        classNamePrefix="custom-select"
      />
    </div>
  );
};

export default CityDropdown;
