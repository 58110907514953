import React from "react";
import { CiUser } from "react-icons/ci";
import { HiOutlineEnvelope } from "react-icons/hi2";

const GetBooking = () => {
  return (
    <div className="mt-[88px] container mx-auto py-12">
      <div className="mx-3">
        <div className="border p-3 max-w-[400px] mx-auto mb-6 shadow-lg">
          <div className="flex justify-between">
            <h1 className="font-medium text-xl">Manage My Booking</h1>
            <p className="text-sm">*required</p>
          </div>
          <p className="mt-4 text-sm">
            Enter the Fly Miles Booking ID and Email address on your booking to
            view and manage your booking.
          </p>
          <form action="">
            <div className="mt-4">
              <label htmlFor="bookingId">Booking ID*</label>
              <div className="flex items-center gap-1 border p-3 mt-2">
                <CiUser />
                <input type="text" className="focus:outline-none" />
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="bookingId">Email Address*</label>
              <div className="flex items-center gap-1 border p-3 mt-2">
                <HiOutlineEnvelope />
                <input type="email" className="focus:outline-none" />
              </div>
            </div>
            <button className="bg-primary w-full text-white p-3 mt-4">
              Manage My Booking
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetBooking;
