import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Tag from "../Component/Tag";
import SectionHeading from "../Component/SectionHeading";
import RatingStars from "../Component/RatingStars";

const reviews = [
  {
    text: "Our Sydney airport transfer was flawless! The driver navigated the city traffic expertly, providing us with a smooth and enjoyable ride to our accommodation. A perfect introduction to the vibrant energy of Sydney!",
    reviwer: "David James",
    occupation: "UI Designer",
    star: 5,
  },
  {
    text: "I cannot express enough gratitude for the fantastic tour organised by Fly Miles. The attention to detail was impeccable, and the guides were not only informative but also made the experience engaging and enjoyable. Highly recommend!",
    reviwer: "Rennie Agarwal",
    occupation: "UI Designer",
    star: 5,
  },
  {
    text: "Sydney's airport transfer service made a positive impact on our trip. The driver's friendly demeanor and local tips made us feel welcome, setting the tone for a memorable stay in this beautiful city. Thank you for the great service!",
    reviwer: "Monica Smith",
    occupation: "UI Designer",
    star: 5,
  },
];

const Review = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/agents/get_clients_reviews.php"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch reviews");
        }
        const data = await response.json();
        setReviews(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) {
    return <div>Loading reviews...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  // console.log(reviews);

  return (
    <>
      <div
        style={{
          backgroundImage: "url(/assets/home/review-bg.png)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="py-24 px-3"
      >
        <div className="container mx-auto">
          <div className="column-12 mb-9">
            <Tag center text={"Testimonials"} />
          </div>
          <div className="column-12 text-center mb-12">
            <SectionHeading
              center
              text={"What Our Traveler Say About Our Tour Services"}
            />
          </div>
          <div className="flex">
            <div className="w-1/3 hidden lg:block">
              <div className="p-4">
                <img
                  src="/assets/home/review-sign.png"
                  alt="review"
                  className="bg-contain"
                />
              </div>
            </div>
            <div className="w-full lg:w-2/3">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                arrows={false}
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={3000}
                dotListClass="custom-dot-list-review"
                infinite
              >
                {reviews.map((x, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center px-[5%] lg:pe-[10%] pb-11 lg:pb-0"
                  >
                    <div className="flex gap-3">
                      <img src="/assets/icons/qoute.png" alt="qoute" />
                      <div>
                        <p className="text-2xl font-semibold">
                          Quality Services
                        </p>
                        <div className="flex text-[#F7921E] mt-2">
                          <RatingStars star={5} />
                        </div>
                      </div>
                    </div>
                    <h2 className="text-[24px] mt-5 text-secondary">
                      {x.description}
                    </h2>
                    <div className="flex gap-3 items-center mt-5">
                      <img
                        className=" rounded-full "
                        src="/assets/home/reviewer-1.png"
                        alt="reviewer-img"
                      />
                      <div>
                        <h2 className="text-[27px] font-medium text-secondary">
                          {x.name}
                        </h2>
                        <p className="text-[18px] font-light text-[#484848]">
                          {x.designation}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
