import React from "react";
import cn from "classnames";
import { CiHeart } from "react-icons/ci";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi2";
import { MdOutlineWatchLater } from "react-icons/md";
import { CiUser } from "react-icons/ci";
import { HiMiniArrowLongRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import RatingStars from "./RatingStars";
import { useNavigate } from "react-router-dom";

const TourPlaceCard = ({
  cardImg,
  featured,
  off,
  rating,
  title,
  place,
  priceData,
  day,
  people,
  exploreBtnLink,
  data,
  onClick,
}) => {
  const navigate = useNavigate();
  const cityNames = place.map((city) => city.city_name).join(", ");
  const getAdultPrice = (data) => {
    if (Array.isArray(data)) {
      // Filter to get only Adult type prices
      const adultPrices = data.filter((item) => item.person_type === "Adult");

      // Find the minimum price from the filtered array
      const minPrice = Math.min(
        ...adultPrices.map((item) => parseInt(item.price))
      );
      return minPrice;
    } else {
      // If data is not an array (single value like "432"), return it directly
      return data;
    }
  };

  // Get the appropriate price based on the data
  const adultPrice = getAdultPrice(priceData);
  // const handleCheckAvaility = (e, tourData) => {
  //   navigate(`/tour-search/${tourData.Product_Header.id}`, {
  //     state: { tourData },
  //   });
  // };
  // console.log(data.prices);
  return (
    <div>
      <div className="relative w-max mx-auto">
        <img
          className=" w-full max-w-[306px] xl:max-w-[350px] 2xl:max-w-[410px] h-[295px] object-cover object-center"
          src={`https://login.flymiles.com.au/api_sections/tours/tour_img/${cardImg.map(
            (img) => img.image
          )}`}
          alt="card img"
        />
        {featured ? (
          <div
            className={cn(
              "bg-[#14B0C3] h-6 flex items-center justify-center text-white uppercase rounded-md text-[11px] absolute left-4 w-[82px]",
              [off ? "top-14" : "top-5"]
            )}
          >
            Featured
          </div>
        ) : null}
        {off ? (
          <div className="bg-[#F7921E] flex justify-center items-center h-6 p-2 text-white uppercase rounded-md text-[11px] absolute top-5 left-4 w-[82px]">
            {off}% off
          </div>
        ) : null}
        <button className="absolute top-5 right-4 bg-[#00000040] text-white w-[30px] h-[30px] rounded flex justify-center items-center text-3xl">
          <CiHeart />
        </button>
        <div className="px-6 pt-9 pb-4 bg-white shadow -top-3 relative rounded-tl-2xl rounded-tr-2xl w-full max-w-[306px] xl:max-w-[350px] 2xl:max-w-[410px]">
          <div className="flex items-center text-[#F7921E]">
            <RatingStars star={rating} />
            <p className="text-secondary font-medium ms-1">({rating})</p>
          </div>
          <h1 className="font-medium text-[22px] text-secondary">{title}</h1>
          <div className="flex items-center text-primary gap-2 my-2">
            <FaMapMarkerAlt />
            <p className="text-secondary">{cityNames}</p>
          </div>
          <div className="flex items-center text-primary gap-2">
            <HiCurrencyDollar />
            <p className="text-secondary">
              Price <span className="text-[#F7921E]">${adultPrice}</span>
            </p>
          </div>
          <div className="flex justify-between mt-4 pt-3 border-t">
            <div className="flex items-center text-primary gap-1">
              <MdOutlineWatchLater />
              <p className="text-secondary">{day}</p>
            </div>
            <div className="flex items-center text-primary gap-1">
              <CiUser />
              <p className="text-secondary">{people}</p>
            </div>
            <button
              className="flex items-center gap-2 font-medium text-secondary"
              // to={exploreBtnLink}
              onClick={() => onClick(exploreBtnLink, data)} 
            >
              Book Now <HiMiniArrowLongRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourPlaceCard;
