import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserDetailsForm from "../Component/UserDetailsForm";
import HeroBanner from "../Sections/HeroBanner";
import Payment from "../Component/Payment";
import CouponCodeInput from "../Component/CouponCodeInput";

const TourBooking = () => {
  const confirmTourNavigate = useNavigate();
  const location = useLocation();
  const { data, bookingDetails, id, total } = location.state;
  const [GST, setGST] = useState(0.0);

  // console.log("hh", bookingDetails, id);
  const handleConfirmBooking = (e, tourBookingData) => {
    confirmTourNavigate(`/tour-booking-confirm`, {
      state: { data, bookingDetails, id },
    });
  };

  // const TotalPrice =
  //   bookingDetails?.net_price - data.Product_Header.promotion_discount_price < 0
  //     ? 0
  //     : bookingDetails?.net_price -
  //       data.Product_Header.promotion_discount_price;
  return (
    <>
      <div className="">
        <HeroBanner text={"Tour"} imgUrl={"/assets/contact/hero-banner.png"} />
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6 mx-3 py-12">
          <div className="lg:w-[calc(70%-12px)]">
            {/* <UserDetailsForm /> */}
            <Payment
              onClick={(e) => handleConfirmBooking(e, data)}
              id={id}
              total={bookingDetails.net_price}
            />
          </div>
          <div className="lg:w-[calc(30%-12px)]">
            <div className="p-3 border rounded">
              <div className="border rounded h-[250px] flex items-center justify-center">
                <img
                  className="h-full w-full rounded flex-none object-over"
                  src={`https://login.flymiles.com.au/api_sections/tours/tour_img/${data.mainImage.map(
                    (img) => img.image
                  )}`}
                  alt=""
                />
              </div>
              <h1 className="font-semibold text-center mt-3 text-xl">
                {data.Product_Header.product_name}
              </h1>
              {/* <CouponCodeInput /> */}
              <div className="mt-6 border-t pt-6">
                <p className="text-xl font-medium mb-3">Booking Details</p>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Travel Date</p>
                  <p>{bookingDetails.booking_date}</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Pickup Time</p>
                  <p>{bookingDetails.pickup_time}</p>
                </div>

                {/* <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Distance</p>
                  <p>9.38 km</p>
                </div> */}
                <div className="flex justify-between gap-3">
                  <p className="font-semibold">Duration</p>
                  <p>{data.Product_Header.durations}</p>
                </div>
              </div>
              <div className="mt-6 border-t pt-6">
                <p className="text-xl font-medium mb-3">Payment</p>
                {/* <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Subtotal</p>
                  <p className="text-seconday">AU${bookingDetails.net_price}</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Tax(GST)</p>
                  <p className="text-seconday">AU${GST}</p>
                </div>
                <div className="flex justify-between gap-3 mb-3">
                  <p className="font-semibold">Pay Amount</p>
                  <p className="text-seconday">
                    AU${parseInt(bookingDetails.net_price) + GST}
                  </p>
                </div> */}

                <div className="flex items-center justify-between gap-2 mb-3">
                  <p className="font-semibold ">SubTotal Cost</p>
                  <p className="text-seconday">A${total}</p>
                </div>
                {data.Product_Header.promotion_discount_price <= 0 ? (
                  <></>
                ) : (
                  <div className="flex items-center justify-between gap-2 mb-3">
                    <p className="font-semibold ">Promotional Discount</p>
                    <p className="text-seconday ">
                      - A${data.Product_Header.promotion_discount_price}
                    </p>
                  </div>
                )}
                <div className="flex items-center justify-between gap-2 mb-3">
                  <p className="font-semibold ">Total Cost</p>
                  <p className="text-seconday ">
                    A$
                    {bookingDetails.net_price}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourBooking;
