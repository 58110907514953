import React from "react";
import { FaTelegramPlane } from "react-icons/fa";

const Button = ({ onClick, text, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={(e) => onClick(e)}
      className="flex mt-3 xl:mt-0 w-max items-center border rounded-full bg-primary text-white font-medium flex-none"
    >
      <span className="px-4 py-3">{text}</span>
      <span className="w-9 h-9 bg-white text-secondary flex justify-center items-center rounded-full me-1">
        <FaTelegramPlane color={"#63AB45"} />
      </span>
    </button>
  );
};

export default Button;
