import React from "react";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <div className="relative min-h-lvh min-w-[100vw]">
      <img
        className="absolute w-full h-full object-cover"
        src="/assets/login/bg.png"
        alt="bg"
      />
      <div className="mx-3">
        <div className="flex justify-center items-center z-10 relative min-h-screen py-6">
          <div className="bg-opacity-20 bg-white bg-blur-lg rounded-lg shadow-lg p-8 max-w-md w-full">
            <h2 className="text-3xl text-center text-white mb-4">Register</h2>
            <form>
              <div className="flex flex-col sm:flex-row gap-3">
                <div className="mb-4">
                  <label htmlFor="firstName" className="block text-white">
                    First Name *
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="w-full px-3 py-2 rounded-md bg-transparent border border-white text-white focus:outline-none focus:border-white"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="lastName" className="block text-white">
                    Last Name *
                  </label>
                  <input
                    type="lastName"
                    id="lastName"
                    name="lastName"
                    className="w-full px-3 py-2 rounded-md bg-transparent border border-white text-white focus:outline-none focus:border-white"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-3">
                <div className="mb-4">
                  <label htmlFor="email" className="block text-white">
                    Your Email *
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full px-3 py-2 rounded-md bg-transparent border border-white text-white focus:outline-none focus:border-white"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="username" className="block text-white">
                    Username *
                  </label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className="w-full px-3 py-2 rounded-md bg-transparent border border-white text-white focus:outline-none focus:border-white"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-3">
                <div className="mb-4">
                  <label htmlFor="password" className="block text-white">
                    Password *
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="w-full px-3 py-2 rounded-md bg-transparent border border-white text-white focus:outline-none focus:border-white"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="confirmPassword" className="block text-white">
                    Confirm Password *
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    className="w-full px-3 py-2 rounded-md bg-transparent border border-white text-white focus:outline-none focus:border-white"
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-primary text-white py-2 rounded-md hover:bg-primary transition duration-300"
              >
                Sign in
              </button>
            </form>

            <div className="flex items-center gap-1 justify-center font-medium mt-4 text-white">
              <p>Already A Member?</p>
              <Link className="text-primary" to={"/login"}>
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
