import React from "react";

const SectionHeading = ({ text, center, mt, maxW, size, leading }) => {
  return (
    <div
      className={`${
        center ? "flex justify-center text-center" : "text-start"
      } ${mt ? `mt-${mt}` : "mt-0"}`}
    >
      <h1
        className={`font-semibold text-${size ? size : "4xl"} leading-[${
          leading ? leading : "48px"
        }] ${maxW ? `max-w-[${maxW}px]` : ""}`}
      >
        {text}
      </h1>
    </div>
  );
};

export default SectionHeading;
