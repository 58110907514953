import React from "react";
import { HiCurrencyDollar } from "react-icons/hi2";
import { FaTelegramPlane } from "react-icons/fa";
import RatingStars from "./RatingStars";

const PackageSingleResult = ({
  imgUrl,
  title,
  star,
  location,
  price,
  date,
  includesHighlight,
  packageIncludes,
  day,
  night,
  id,
  onClick,
  smallText,
}) => {
  // console.log(includesHighlight);
  return (
    <div className="flex flex-col lg:flex-row relative items-center mb-9">
      <img
        className="w-full lg:w-2/5 xl:w-1/3 h-[350px] xl:h-[250px] object-cover object-center rounded-xl"
        src={imgUrl}
        alt="img"
      />
      <div className="w-full lg:w-[calc(60%+24px)] xl:w-[calc(66.66667%+24px)] border rounded-2xl lg:rounded-lg lg:-ms-6 -mt-6 lg:-mt-0 bg-white px-3 lg:px-4 py-5 flex-none">
        <div className="sm:flex justify-between">
          <div>
            <h1 className="text-lg font-semibold mb-1">
              {title}
              {/* ({night}Night / {day} Days) */}
            </h1>
            <h2 className="font-medium mb-2">
              {location.map((city) => (
                <>{city.city_name} </>
              ))}
            </h2>
            {/* <div className="flex items-center mb-1">
              <RatingStars star={star} />
              <p className="ms-1 font-medium">({star})</p>
            </div> */}
          </div>
          <div className="flex flex-none sm:flex-col justify-between flex-wrap sm:items-end">
            <div className="flex items-center gap-1">
              <HiCurrencyDollar color="#63AB45" />
              <p className="font-medium">Staring From</p>
            </div>
            <p className="text-xl font-bold">AU$ {price}</p>
            <p className="text-sm w-full">Per Person on twin share</p>
          </div>
        </div>
        <div className="xl:flex items-center justify-between mt-4">
          <div className="flex flex-wrap gap-3">
            {includesHighlight?.map((x, index) => (
              <div
                className="flex flex-col items-center text-center"
                key={index}
              >
                <img className="h-8 w-8" src={x.image} alt="" />
                <p className="mt-2">{x.name}</p>
              </div>
            ))}
          </div>
          <button
            onClick={() => onClick()}
            className="flex mt-6 xl:mt-0 w-max items-center border rounded-full bg-primary text-white font-medium flex-none"
          >
            <span className="px-4 py-3">Book Now</span>
            <span className="w-9 h-9 bg-white text-secondary flex justify-center items-center rounded-full me-1">
              <FaTelegramPlane color={"#63AB45"} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackageSingleResult;
