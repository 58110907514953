import React from "react";
import Flags from "react-flags-select";

const CountryDropdown = ({ selectedCountry, setSelectedCountry }) => {
  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode);
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="relative inline-block">
        <Flags
          selected={selectedCountry}
          onSelect={handleCountryChange}
          alignOptionsToRight
          countries={["AU", "US"]}
          customLabels={{ AU: " ", US: " " }} // Add empty space as a label to hide it
          showSelectedLabel={false} // Hide the selected country label
          className="rounded-md focus:outline-none appearance-none !p-0"
          // Apply additional styles to remove the right side caret and outline
          style={{
            padding: 0, // Adjust this value to align the right content
            boxShadow: "none",
            border: "none !important",
            outline: "none !important",
          }}
        />
      </div>
    </div>
  );
};

export default CountryDropdown;
