import React from "react";
import HeroBanner from "../Sections/HeroBanner";
import { useLocation } from "react-router-dom";

const transferUserData = [
  {
    label: "First Name",
    value: "Smith Josh",
  },
  {
    label: "Phone",
    value: "+0125 125 1254",
  },
  {
    label: "Email",
    value: "sjosh@mail.com",
  },
  {
    label: "Flight Number",
    value: "121-2584",
  },
  {
    label: "Country",
    value: "Australia",
  },
  {
    label: "City",
    value: "Sydney",
  },
  {
    label: "Remarks",
    value: "This is an awesome transfer",
  },
];

const TransferBookingConfim = () => {
  const location = useLocation();
  const transferBookingData = location.state?.transferBookingData;
  return (
    <>
      <div className="">
        <HeroBanner
          text={"Confirmation"}
          imgUrl={"/assets/contact/hero-banner.png"}
        />
      </div>
      <div className="container mx-auto">
        <div className="mx-3 py-6">
          <h1 className="text-2xl font-semibold">Confirmation</h1>
          <p className="border-primary border-2 p-4 text-center bg-[#F0F8FF] mt-4">
            Your booking is completed, we will send you confirmation email
            shortly.
          </p>
          <div className="flex items-center gap-2 mt-4">
            <p className="font-medium">Booking Number</p>
            <p className="font-medium">#230630-040008185</p>
          </div>
          <div className="p-4 flex justify-between border gap-4 rounded-lg mt-4">
            <div className="flex flex-col md:flex-row w-[70%] gap-3">
              <div className="border rounded h-max py-6 sm:w-[250px] flex items-center justify-center">
                <img
                  className="h-max md:w-[220px] flex-none object-contain"
                  src={transferBookingData.imgUrl}
                  alt=""
                />
              </div>
              <div>
                <h1 className="font-semibold text-xl">
                  {transferBookingData.vehicleCategory}(
                  {transferBookingData.title})
                </h1>
                <div className="flex items-center gap-4 mt-3">
                  <div className="flex items-center gap-3">
                    <p className="font-semibold">Date:</p>
                    <p>{transferBookingData.travelDate}</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="font-semibold">Time:</p>
                    <p>{transferBookingData.travelTime}</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 mt-3">
                  <p className="font-semibold">Duration:</p>
                  <p>{transferBookingData.duration}</p>
                </div>
              </div>
            </div>
            <h1 className="text-primary font-semibold text-xl">$320.00</h1>
          </div>
          <div className="rounded p-4 bg-[#F3F8F6] flex items-end flex-col gap-3 mt-6">
            <p>Subtotal: $328.00</p>
            <p>Total: $328.00</p>
            <p>Amount Paid: $0.00</p>
            <p>Amount Due: $328.00</p>
          </div>
          <div className="overflow-x-auto mt-6">
      <table className="w-full border-collapse border border-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          {transferUserData.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.label}</td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </div>
      </div>
    </>
  );
};

export default TransferBookingConfim;
