import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import CityDropdown from "./CityDropdown";

const PackageSearch = ({ resultPage }) => {
  const [startDate, setStartDate] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [message, setMessage] = useState("");
  // const history = useHistory();
  const navigate = useNavigate(); // Create the navigate function using useNavigate hook
  const location = useLocation();

  useEffect(() => {
    // Set initial values if they exist in location.state
    if (location.state && location.state.selectedDate && location.state.city) {
      setStartDate(new Date(location.state.selectedDate));
      setCityId(location.state.city);
    }
  }, [location.state]);

  // Function to format date to YYYY-MM-DD
  const formatDate = (date) => {
    if (!date) return null;
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    return `${d.getFullYear()}-${month}-${day}`;
  };

  // Function to handle the API request
  const handleSearch = async () => {
    if (!startDate || !cityId) {
      alert("Please select both date and city.");
      return;
    }

    const formattedDate = formatDate(startDate);
    const requestBody = {
      filter_date: formattedDate,
      filter_city: cityId,
    };

    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/packages/get_prodcut_complete_detail_filter.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await response.json();
      // console.log(data); // You might want to handle this data differently
      if (data?.length === 0) {
        setMessage("No Result Found");
      }
      localStorage.setItem("startDate", startDate);

      // Optionally redirect or do something with the data
      navigate("/package-search", {
        state: { data: data, selectedDate: startDate, city: cityId },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/package-search", {
        state: {
          data: "No Result Found",
          selectedDate: startDate,
          city: cityId,
        },
      });
    }
  };

  return (
    <div
      className={`${
        resultPage
          ? "w-max lg:flex bg-white p-3 border rounded-lg gap-4 mx-3 shadow-lg"
          : "w-full"
      }`}
    >
      <div
        className={`${
          resultPage
            ? "flex gap-2 pb-2 border-b lg:w-[270px]"
            : "flex gap-2 pb-2 border-b"
        }`}
      >
        <img
          className=" flex-none h-10 w-10"
          src="/assets/icons/location1.png"
          alt="location"
        />
        <div className="w-full z-20">
          <div className="flex w-full justify-between text-[#626262] items-center">
            <label htmlFor="Location">Location (Pick-up from)</label>
          </div>
          <CityDropdown
            packageSearch
            onSelectCity={setCityId}
            initialCityId={cityId}
          />
        </div>
      </div>
      <div
        className={`${
          resultPage
            ? "flex items-end gap-4 mt-4 lg:mt-0"
            : "flex flex-col sm:flex-row items-end gap-4 mt-4"
        }`}
      >
        <div
          className={`${
            resultPage
              ? "flex flex-none gap-2 pb-2 border-b w-1/2 sm:w-[270px]"
              : "flex gap-2 pb-2 border-b w-full sm:w-2/3"
          }`}
        >
          <img
            className=" flex-none h-10 w-10"
            src="/assets/icons/calendar.png"
            alt="calendar"
          />
          <div className="w-full package-date">
            <label className="text-[#626262] block mb-1" htmlFor="Date">
              Date
            </label>
            <DatePicker
              locale={"au"}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy" // Specify the date format
              minDate={new Date(new Date().setDate(new Date().getDate() + 2))}
            />
          </div>
        </div>
        <Link
          to={"/package-search"}
          onClick={handleSearch}
          className=" bg-primary z-10 flex items-center justify-center h-12 text-center rounded-lg text-white w-full px-6 sm:w-1/3"
        >
          Search
        </Link>
      </div>
    </div>
  );
};

export default PackageSearch;
