import React, { useEffect, useState } from "react";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css"; // Basic Draft.js styles

const RichTextDisplay = ({ rawDraftContentState }) => {
  const [data, setData] = useState(null);
  const preprocessAndParseJson = (jsonString) => {
    try {
      // Replace unescaped newlines and other potentially problematic escape sequences
      const sanitizedJsonString = jsonString.replace(/\n/g, "\\n");
      return JSON.parse(sanitizedJsonString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null; // or however you wish to handle parsing errors
    }
  };
  // Parse the JSON string to a JavaScript object
  const rawContent = preprocessAndParseJson(rawDraftContentState);
  //   useEffect(() => {
  //     if (rawContent) {
  //       // Replace newline characters with properly escaped newlines
  //       const formattedJsonData = rawContent.replace(/\n/g, "\\n");

  //       try {
  //         // Parse the corrected JSON data
  //         const parsedData = JSON.parse(formattedJsonData);
  //         setData(parsedData);
  //         console.log("Parsed Data:", parsedData);
  //       } catch (error) {
  //         console.error("Error parsing JSON:", error);
  //       }
  //     }
  //   }, [rawContent]); // Effect runs whenever jsonData changes

  // Convert the raw content to an EditorState object
  const contentState = convertFromRaw(rawContent);
  const editorState = EditorState.createWithContent(contentState);

  return (
    <div style={{ padding: 20 }}>
      {/* Render the Editor in read-only mode */}
      <Editor editorState={editorState} readOnly={true} />
    </div>
  );
};

export default RichTextDisplay;
