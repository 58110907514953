import React from "react";

const UserDetailsForm = ({ packageDetails }) => {
  return (
    <div className="border p-3 rounded-lg">
      <h2 className="font-semibold text-xl">Let us know who you are</h2>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex gap-2 pb-2 border-b w-full mt-4">
          <img
            className="h-10 w-10 object-contain"
            src="/assets/icons/user-list.png"
            alt="time"
          />
          <div className="w-full tour-date">
            <label
              className="text-[#626262] font-medium block mb-1"
              htmlFor="time"
            >
              Passenger Name
            </label>
            <input type="text" placeholder="Name of passenger" />
          </div>
        </div>
        <div className="flex gap-2 pb-2 border-b w-full mt-4">
          <img
            className="h-10 w-10 object-contain"
            src="/assets/icons/call.png"
            alt="time"
          />
          <div className="w-full tour-date">
            <label
              className="text-[#626262] font-medium block mb-1"
              htmlFor="time"
            >
              Phone
            </label>
            <input type="tel" placeholder="phone number of passenger" />
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex gap-2 pb-2 border-b w-full mt-4">
          <img
            className="h-10 w-10 object-contain"
            src="/assets/icons/plane.png"
            alt="time"
          />
          <div className="w-full tour-date">
            <label
              className="text-[#626262] font-medium block mb-1"
              htmlFor="time"
            >
             Flight Number / Gruise Details
            </label>
            <input type="text" placeholder="Name of passenger" />
          </div>
        </div>
        <div className="flex gap-2 pb-2 border-b w-full mt-4">
          <img
            className="h-10 w-10 object-contain"
            src="/assets/icons/email.png"
            alt="time"
          />
          <div className="w-full tour-date">
            <label
              className="text-[#626262] font-medium block mb-1"
              htmlFor="time"
            >
              Email Address
            </label>
            <input type="mail" placeholder="Email of passenger" />
          </div>
        </div>
      </div>
      <div className="flex gap-2 pb-2 border-b w-full mt-4">
        <img
          className="h-10 w-10 object-contain"
          src="/assets/icons/location.png"
          alt="time"
        />
        <div className="w-full tour-date">
          <label
            className="text-[#626262] font-medium block mb-1"
            htmlFor="time"
          >
            Country
          </label>
          <input type="text" placeholder="Country of Passenger" />
        </div>
      </div>
      <div className="flex gap-2 pb-2 border-b w-full mt-4">
        <img
          className="h-10 w-10 object-contain"
          src="/assets/icons/remark.png"
          alt="time"
        />
        <div className="w-full tour-date">
          <label
            className="text-[#626262] font-medium block mb-1"
            htmlFor="time"
          >
            Special Requirement
          </label>
          <textarea placeholder="Passenger's Messages" className="w-full" name="" id="" rows="1"></textarea>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsForm;
