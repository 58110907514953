import React from "react";

const Tag = ({ text, center }) => {
  return (
    <div className={`${center ? "flex justify-center" : ""}`}>
      <div className="bg-primary_2 w-max font-medium text-primary py-2 px-5 rounded-md">
        {text}
      </div>
    </div>
  );
};

export default Tag;
