import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "./Button";
import { useLocation } from "react-router-dom";
import { HiMiniMinusSmall, HiMiniPlusSmall } from "react-icons/hi2";
import { format } from "date-fns";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const BookingPackage = ({ onClick, location, language, data }) => {
  const [startDate, setStartDate] = useState(null);
  const [adultQnt, setAdultQnt] = useState(0);
  const [childQnt, setChildQnt] = useState(0);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [city, setCity] = useState(null);
  const [options, setOptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tagSuccess, setTagSuccess] = useState(false);
  const [tagDanger, setTagDanger] = useState(false);

  const locationState = useLocation();
  const selectedDate = locationState.state?.selectedDate || null;
  useEffect(() => {
    // Retrieve startDate from local storage
    const storedStartDate = localStorage.getItem("startDate");
    if (storedStartDate) {
      setStartDate(storedStartDate);
    }
  }, []);
  useEffect(() => {
    if (selectedDate) {
      setStartDate(selectedDate);
    }
  }, [selectedDate]);
  const formatDate = (date) => {
    return format(new Date(date), "dd/MM/yyyy");
  };
  const handleSubmit = async () => {
    const bookingDetails = {
      product_id: data?.Product_Header?.id, // Assuming product_id is static
      user_name: name,
      no_of_pax: adultQnt + childQnt,
      user_email: email,
      user_phone: phone,
      bk_date: formatDate(startDate), // Format date as YYYY-MM-DD
      bk_city: city,
      no_of_adults: adultQnt,
      no_of_child: childQnt,
      price_adult: data ? data?.Product_Header?.price_adult : "", // Assuming price is static
      price_child: data ? data?.Product_Header?.price_child : "", // Assuming price is static
      price_total: TotalPrice.toString(), // Ensure total is a string if API requires it
    };

    // console.log(bookingDetails);

    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/packages/create_pkg_booking.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookingDetails),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok.");
      const data = await response.json();
      // console.log(data);
      setLoading(false);
      if (data.success) {
        setTagSuccess(
          "Thank you for submitting your Package Request, our representative will contact you via email/phone shortly!"
        );
      }
      if (data.success) {
        setTagSuccess(
          "Thank you for submitting your Package Request, our representative will contact you via email/phone shortly !"
        );
        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setCity("");
        setStartDate(null);
        setAdultQnt(0);
        setChildQnt(0);
      } else {
        throw new Error(data.error || "Failed to create booking.");
      }

      // Handle successful booking here (e.g., display a message, redirect, etc.)
    } catch (error) {
      console.error("Failed to create booking:", error);
      // Handle errors here (e.g., display error message)
      if (error) {
        setTagDanger("There is some issue booking your tour, please try again");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const total =
      data.Product_Header.price_child * childQnt +
      data.Product_Header.price_adult * adultQnt;

    setTotal(total);
  }, [childQnt, adultQnt]);

  useEffect(() => {
    if (data && Array.isArray(data.cities)) {
      const options = data.cities.map((item) => ({
        value: item.city_id, // Assuming 'city_name' is the unique identifier for each city
        label: item.city_name, // The display text you want to show for each option
      }));
      setOptions(options);
      // console.log(options);
    }
  }, [data]);
  const TotalPrice =
    total - data.Product_Header.promotion_discount_price < 0
      ? 0
      : total - data.Product_Header.promotion_discount_price;
  // console.log(data.cities, city, name, email, startDate, phone);
  // console.log(loading);
  return (
    <>
      <div className="bg-[#F9F9F7] px-3 py-6 rounded-lg">
        <div className="flex items-center gap-2 border-b mb-3 pb-6">
          <img
            src="/assets/icons/location-2.png"
            alt=""
            className="flex-none w-9 h-9 object-contain"
          />
          <h1 className="text-lg font-semibold">Booking Package</h1>
        </div>
        <div className="flex items-center justify-between gap-2 border-b mb-3 pb-3">
          <h2 className="font-semibold">Date</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <img
              className="w-6 object-contain h-6"
              src="/assets/icons/calendar.png"
              alt="calendar"
            />
            <DatePicker
              required
              disabled={loading}
              locale={"au"}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy" // Specify the date format
              minDate={new Date()}
              className="w-full"
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Name</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <input
              required
              type="text"
              disabled={loading}
              placeholder="Enter Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Email</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <input
              required
              type="email"
              disabled={loading}
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        {/* <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Phone</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <input
              required
              type="tel"
              disabled={loading}
              placeholder="Enter Your Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div> */}
        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Phone</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <PhoneInput
              country={"au"} // You can set the default country code here
              value={phone}
              onChange={(phone) => setPhone(phone)}
              inputStyle={{
                width: "100%",
                border: "none",
                outline: "none",
              }}
              buttonStyle={{
                border: "none",
                outline: "none",
              }}
              disabled={loading}
            />
          </div>
        </div>

        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <h2 className="font-semibold">Arrival City</h2>
          <div className="flex w-[70%] items-center gap-2 border rounded p-2 bg-white">
            <select
              required
              value={city}
              disabled={loading}
              onChange={(e) => setCity(e.target.value)}
              className="w-full" // corrected className from "w[100%]" to "w-full"
              placeholder="Select City"
            >
              <option value="">Select City</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <h1 className="text-lg font-semibold border-b pb-3 mb-3">Pricing</h1>
        <div className="flex items-center justify-between gap-2 border-b mb-3 pb-3">
          <div className="flex items-center gap-1">
            <h2 className="font-semibold">Adult</h2>
            <span className="text-[#A5A5A5]">(18+ years) </span>
          </div>
          <div className="flex w-max items-center gap-2 border rounded p-2 bg-white">
            <img
              className="w-6 object-contain h-6"
              src="/assets/icons/user.png"
              alt="calendar"
            />
            <input
              onChange={(e) => setAdultQnt(e.target.value)}
              type="number"
              disabled={loading}
              placeholder="0"
              value={adultQnt}
              className="w-[24px] number-input focus:outline-none"
            />
            <div className="flex items-center gap-1">
              <button
                disabled={loading}
                onClick={() =>
                  setAdultQnt(adultQnt > 0 ? parseInt(adultQnt) - 1 : 0)
                }
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniMinusSmall />
              </button>
              <button
                disabled={loading}
                onClick={() => setAdultQnt(parseInt(adultQnt) + 1)}
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniPlusSmall />
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 border-b mb-6 pb-3">
          <div className="flex items-center gap-1">
            <h2 className="font-semibold">Child</h2>
            <span className="text-[#A5A5A5]">(0-12 years)</span>
          </div>
          <div className="flex w-max items-center gap-2 border rounded p-2 bg-white">
            <img
              className="w-6 object-contain h-6"
              src="/assets/icons/user.png"
              alt="calendar"
            />
            <input
              onChange={(e) => setChildQnt(e.target.value)}
              type="number"
              disabled={loading}
              placeholder="0"
              value={childQnt}
              className="w-[24px] number-input focus:outline-none"
            />
            <div className="flex items-center gap-1">
              <button
                disabled={loading}
                onClick={() =>
                  setChildQnt(childQnt > 0 ? parseInt(childQnt) - 1 : 0)
                }
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniMinusSmall />
              </button>
              <button
                disabled={loading}
                onClick={() => setChildQnt(parseInt(childQnt) + 1)}
                className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
              >
                <HiMiniPlusSmall />
              </button>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between gap-2 mb-3">
          <p className="font-bold text-lg">SubTotal Cost</p>
          <p className="font-bold text-lg">A${total}</p>
        </div>
        {data.Product_Header.promotion_discount_price <= 0 ? (
          <></>
        ) : (
          <div className="flex items-center justify-between gap-2 mb-3">
            <p className="font-bold text-lg">Promotional Discount</p>
            <p className="font-bold text-lg">
              - A${data.Product_Header.promotion_discount_price}
            </p>
          </div>
        )}

        <div className="flex items-center justify-between gap-2 mb-3">
          <p className="font-bold text-lg">Total Cost</p>
          <p className="font-bold text-lg">
            A$
            {TotalPrice}
          </p>
        </div>
        <div className="flex justify-end mb-3">
          <Button
            disabled={loading}
            text={loading ? "Booking..." : "Book Now"}
            onClick={(e) => {
              e.preventDefault();
              setLoading(true);
              handleSubmit();
            }}
          />
        </div>

        {tagSuccess ? (
          <div className={"flex justify-center"}>
            <div className="bg-primary_2 w-max font-medium text-primary py-2 px-5 rounded-md">
              {tagSuccess}
            </div>
          </div>
        ) : tagDanger ? (
          <div className={"flex justify-center"}>
            <div className="bg-red-100 w-max font-medium text-red-400 py-2 px-5 rounded-md">
              {tagDanger}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {/* tour information */}
      {/* <div className="border px-3 py-6 rounded-lg mt-6">
        <div className="flex items-center gap-2 border-b mb-3 pb-6">
          <img
            src="/assets/icons/location-2.png"
            alt=""
            className="flex-none w-9 h-9 object-contain"
          />
          <h1 className="text-lg font-semibold">Tour Information</h1>
        </div>
        {tourInformation.map((x, index) => (
          <div
            key={index}
            className={`flex items-center justify-between gap-2 border-b mb-3 pb-3 ${
              index === tourInformation.length - 1 ? "border-b-0 mb-0 pb-0" : ""
            } `}
          >
            <div className="flex items-center gap-1">
              <FaRegUserCircle color="#63AB45" />
              <h2 className="font-medium">{x.title}</h2>
            </div>
            <p className="font-bold text-lg">{x.value}</p>
          </div>
        ))}
      </div> */}

      {/* last minutes deal */}
      {/* <div className="border px-3 py-6 rounded-lg mt-6">
        <div className="flex items-center gap-2 border-b mb-3 pb-6">
          <img
            src="/assets/icons/location-2.png"
            alt=""
            className="flex-none w-9 h-9 object-contain"
          />
          <h1 className="text-lg font-semibold">Last Minute Deals</h1>
        </div>
        {lastMinutesDeals.map((x, index) => (
          <div
            key={index}
            className={`flex items-center gap-3 border-b mb-3 pb-3 ${
              index === lastMinutesDeals.length - 1
                ? "border-b-0 mb-0 pb-0"
                : ""
            } `}
          >
            <img
              src={x.imgUrl}
              alt="img"
              className="w-[30%] h-[100px] object-cover rounded-lg"
            />
            <div className="flex flex-col gap-2">
              <RatingStars star={5} />
              <Link
                className="font-semibold text-lg hover:text-primary transition"
                to={"/"}
              >
                {x.title}
              </Link>
              <p>
                From{" "}
                <span className="font-medium text-primary">${x.price}</span>
              </p>
            </div>
          </div>
        ))}
      </div> */}

      {/* extra features */}
      {/* {extraFeature.map((x, index) => (
        <div key={index} className="relative mt-6">
          <img
            className="h-[40vh] w-full rounded-lg object-cover"
            src={x.imgUrl}
            alt=""
          />
          <div className="absolute w-full h-full bg-black opacity-50 left-0 top-0 rounded-lg"></div>
          <div className="absolute bottom-6 left-6">
            <h1 className="font-semibold text-xl text-white mb-2">{x.title}</h1>
            <div className="flex items-center text-lg text-white gap-2">
              <MdLocationOn color="#63AB45" />
              <p>{x.location}</p>
            </div>
          </div>
        </div>
      ))} */}
    </>
  );
};

export default BookingPackage;
