import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SectionHeading from "../Component/SectionHeading";
import { FaMapMarkerAlt } from "react-icons/fa";
import { PiShareFatLight } from "react-icons/pi";
import { CiHeart } from "react-icons/ci";
import { BiSolidBadgeCheck } from "react-icons/bi";
import TourPlaceCard from "../Component/TourPlaceCard";
import BookingTour from "../Component/BookingTour";
import RatingStars from "../Component/RatingStars";
import RichTextDisplay from "../Component/RichTextDisplay";

const TourBookingDetails = () => {
  const location = useLocation();
  const tourData = location?.state?.tourData;
  const tourDataNavigate = useNavigate();
  const tourDataImgs = tourData?.images?.map((img) => img?.image);
  const [showTimes, setShowTimes] = useState({});
  const [days, setDays] = useState(0);
  const [price, setPrice] = useState(0);
  const [groupedPrices, setGroupedPrices] = useState([]);
  const navigate = useNavigate();

  // console.log("Data", tourData);
  // const handleCheckAvaility = (e, tourData) => {
  //   navigate(`/tour-search/${tourData.Product_Header.id}`, {
  //     state: { tourData },
  //   });
  // };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  let minAdultPrice = 0;
  // console.log(tourData.prices);
  useEffect(() => {
    if (
      tourData?.prices &&
      tourData?.prices?.length > 0 &&
      tourData?.prices !== "Per Person "
    ) {
      // Extracting the minimum price for "Adult"

      const adultPrices = tourData?.prices
        ?.filter((price) => price.person_type === "Adult")
        .map((price) => parseFloat(price.price));

      if (adultPrices.length > 0) {
        minAdultPrice = Math.min(...adultPrices);
        setPrice(minAdultPrice);
        // console.log(minAdultPrice);
      } else {
        // console.log("No prices found for adults.");
      }
    } else if (tourData?.prices === "Per Person ") {
      setPrice(tourData.Product_Header.price_adult);
    } else {
      // console.log("No prices available.");
    }
  }, [tourData]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
    },
  };

  const relatedTourResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  const toggleShowTimes = (groupType) => {
    setShowTimes((prevShowTimes) => ({
      ...prevShowTimes,
      [groupType]: !prevShowTimes[groupType],
    }));
  };

  const groupedTimes = tourData?.times?.reduce((acc, time) => {
    if (!acc[time.group_type]) {
      acc[time.group_type] = [];
    }
    acc[time.group_type].push(time.pickup_time);
    return acc;
  }, {});

  // console.log(tourData?.prices);
  useEffect(() => {
    if (
      tourData?.prices &&
      tourData?.prices?.length > 0 &&
      tourData?.prices !== "Per Person "
    ) {
      // Extracting the minimum price for "Adult"

      const groupedPrices = tourData?.prices?.reduce((acc, price) => {
        if (!acc[price.group_type]) {
          acc[price.group_type] = [];
        }
        acc[price.group_type].push(price);
        return acc;
      }, {});
      setGroupedPrices(groupedPrices);
    } else if (tourData?.prices === "Per Person ") {
    } else {
      // console.log("No prices available.");
    }
  }, [tourData]);
  // console.log();

  const accessibilities =
    tourData?.accessibility?.filter((item) => item.yes_no) || [];
  const questions = tourData?.questions?.filter((item) => item.answer) || [];

  const healthRestrictions =
    tourData?.health_restrication?.filter(
      (item) => item.check_uncheck === "yes"
    ) || [];

  const include =
    tourData?.inclusions_exclusion?.filter((item) => item.type === "include") ||
    [];
  const exclude =
    tourData?.inclusions_exclusion?.filter((item) => item.type === "exclude") ||
    [];

  const handleTourBooking = (e, tourData) => {
    tourDataNavigate(`/tour-booking`, {
      state: { tourData, duration: tourData?.daywise?.length },
    });
  };

  return (
    <div>
      <div className="mt-[88px]">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          arrows={false}
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite
          keyBoardControl={true}
        >
          {tourDataImgs?.map((imgUrl, index) => (
            <img
              key={index}
              className="h-[60vh] w-full object-cover px-3"
              src={`https://login.flymiles.com.au/api_sections/tours/tour_img/${imgUrl}`}
              alt={`Img ${index + 1}`}
            />
          ))}
        </Carousel>
      </div>
      <div className="container mx-auto">
        <div className="mx-3 py-12">
          <div className="flex flex-col lg:flex-row lg:items-center justify-between pb-12 border-b gap-4">
            <div>
              <SectionHeading
                size={"3xl"}
                text={tourData?.Product_Header?.product_name}
              />
              <div className="flex items-center gap-2 mt-4">
                <FaMapMarkerAlt color="#63AB45" />
                <p>
                  {tourData?.cities?.map((city, index) => (
                    <span key={index}>
                      {city.city_name}
                      {index < tourData?.cities?.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </p>
              </div>
            </div>
            <div className="flex items-center flex-wrap gap-4">
              <div className="flex items-center gap-2">
                <img
                  className="h-9 w-9 object-contain"
                  src={"/assets/icons/from.png"}
                  alt="from"
                />
                <div>
                  <p>From</p>
                  <p className="font-semibold text-xl">${price}</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img
                  className="h-9 w-9 object-contain"
                  src={"/assets/icons/day.png"}
                  alt="day"
                />
                <div>
                  <p>Duration</p>
                  <p className="font-semibold text-xl">
                    {" "}
                    {tourData?.Product_Header?.durations}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img
                  className="h-9 w-9 object-contain"
                  src={
                    tourData?.Product_Header?.tour_type == "private"
                      ? "/assets/icons/private.png"
                      : "/assets/icons/shared.png"
                  }
                  alt="city"
                />
                <div>
                  <p>Tour Type</p>
                  <p className="font-semibold text-xl">
                    {capitalizeFirstLetter(tourData?.Product_Header?.tour_type)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center justify-between py-4 border-b gap-4">
            <div className="flex items-center text-[#F7921E]">
              <RatingStars star={5} />
              <p className="text-secondary font-medium ms-1">(5 Reviews)</p>
            </div>
            <div className="flex items-center flex-wrap gap-4">
              {/* <Link
                to={"/"}
                className="py-3 px-4 flex items-center gap-2 bg-primary rounded-full text-white"
              >
                <p className="uppercase">Share</p>
                <PiShareFatLight />
              </Link> */}
              {/* <Link
                to={"/"}
                className="py-3 px-4 flex items-center gap-2 bg-[#E8E9E9] rounded-full"
              >
                <p className="uppercase">Share</p>
                <PiShareFatLight />
              </Link>
              <Link
                to={"/"}
                className="py-3 px-4 flex items-center gap-2 bg-[#E8E9E9] rounded-full"
              >
                <p className="uppercase">Wishlist</p>
                <CiHeart />
              </Link> */}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-6 mx-3 py-12">
            <div className="lg:w-[calc(100%-374px)]">
              <SectionHeading
                text={"Explore Tour"}
                size={"2xl"}
                leading={"28px"}
                mt={6}
              />
              <p className="mt-4">
                {/* {tourData.Product_Header.prodcut_inclusion_high} */}
              </p>
              {/* <RichTextDisplay
                rawDraftContentState={
                  tourData?.Product_Header?.prodcut_inclusion_high
                    ? tourData?.Product_Header?.prodcut_inclusion_high
                    : ""
                }
              /> */}

              <SectionHeading
                size={"2xl"}
                leading={"28px"}
                mt={6}
                text={"Tour Plan"}
              />
              <div className="relative">
                <div className="h-full w-[3px] -z-10 bg-primary left-4 absolute"></div>
                <div className="mt-6">
                  {tourData?.daywise?.map((x, index) => (
                    <div key={index} className="flex gap-3">
                      <div className="flex-none w-9 h-9 flex items-center justify-center">
                        {index === 0 ? (
                          <p className="h-8 w-8 rounded-full flex items-center justify-center font-semibold text-white bg-primary -mt-1">
                            {index + 1}
                          </p>
                        ) : (
                          <p className="h-8 w-8 rounded-full flex items-center justify-center font-semibold text-white bg-primary -mt-1">
                            {index + 1}
                          </p>
                        )}
                      </div>
                      <div>
                        <h1 className="font-semibold text-xl">{x.header}</h1>
                        <p className="my-3">{x.body}</p>
                        <div className="flex items-center gap-3 mb-4 -mt-2">
                          {x.duration && (
                            <p className="text-sm">{x.duration}</p>
                          )}
                          {x.include && (
                            <p className="flex items-center gap-2 text-sm">
                              <span className="w-1 h-1 bg-secondary rounded-full"></span>{" "}
                              {x.include}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <SectionHeading
                size={"2xl"}
                leading={"28px"}
                mt={6}
                text={"Inclusion"}
              />
              <p className="mt-4">{}</p>
              <div className="flex gap-4 mt-4">
                <div className="md:w-[calc(100%-8px)] flex-none">
                  {include?.map((x, index) => (
                    <div
                      key={index}
                      className="flex text-xl mb-2 gap-2 items-center"
                    >
                      <BiSolidBadgeCheck color="#63AB45" />
                      <p className="font-medium text-sm">{x.title}</p>
                    </div>
                  ))}
                </div>
              </div>
              <SectionHeading
                size={"2xl"}
                leading={"28px"}
                mt={6}
                text={"Exclusion"}
              />
              <p className="mt-4">{}</p>
              <div className="flex gap-4 mt-4">
                <div className="md:w-[calc(100%-8px)] flex-none">
                  {exclude?.map((x, index) => (
                    <div
                      key={index}
                      className="flex text-xl mb-2 gap-2 items-center"
                    >
                      <BiSolidBadgeCheck color="#1C231Fa2" />
                      <p className="font-medium text-sm">{x.title}</p>
                    </div>
                  ))}
                </div>
              </div>
              <SectionHeading
                size={"2xl"}
                leading={"28px"}
                mt={6}
                text={"Languages"}
              />
              <div className="flex gap-4 mt-4">
                <div className="md:w-[calc(100%-8px)] flex-none">
                  {tourData?.languages
                    ?.filter(
                      (lang) =>
                        lang.inperson === "yes" ||
                        lang.audio === "yes" ||
                        lang.written === "yes"
                    )
                    .map((lang, index) => (
                      <div
                        key={index}
                        className="flex text-xl mb-2 gap-2 items-center"
                      >
                        <BiSolidBadgeCheck color="#63AB45" />
                        <p className="font-medium text-sm">
                          {lang.language} -{" "}
                          {lang.inperson === "yes" && "In-person"}{" "}
                          {lang.audio === "yes" && "Audio"}{" "}
                          {lang.written === "yes" && "Written"}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
              {tourData?.prices !== "Per Person " ? (
                <>
                  <SectionHeading
                    size={"2xl"}
                    leading={"28px"}
                    mt={6}
                    text={"Available Times and Prices"}
                  />
                  <div className="flex flex-col gap-4 mt-4">
                    {groupedTimes &&
                      Object.keys(groupedTimes).map((groupType, index) => (
                        <div key={index} className="border rounded-lg p-4">
                          <div className="flex justify-between items-center mb-2">
                            <h2 className="text-lg font-semibold">
                              {groupType}
                            </h2>
                            <button
                              onClick={() => toggleShowTimes(groupType)}
                              className="text-primary"
                            >
                              {showTimes[groupType] ? "Show less" : "Show more"}
                            </button>
                          </div>
                          {showTimes[groupType] && (
                            <>
                              {groupedTimes[groupType].map((time, index) => (
                                <span
                                  key={index}
                                  className="border-[#63AB45] border-2 p-1 px-3 rounded-md mr-2"
                                >
                                  {time}
                                </span>
                              ))}

                              <div className="">
                                {groupedPrices[groupType]?.map(
                                  (price, idx, arr) => {
                                    // Find the first index of the current person_type
                                    const firstOccurrence = arr.findIndex(
                                      (item) =>
                                        item.person_type === price.person_type
                                    );

                                    return (
                                      <div key={idx} className="mt-4">
                                        {/* Render person_type only if it's the first occurrence */}
                                        {firstOccurrence === idx && (
                                          <div className="flex items-center mb-2">
                                            <p className="font-semibold text-lg text-primary">
                                              {price.person_type}:
                                            </p>
                                          </div>
                                        )}
                                        <div className="flex items-center justify-between pb-1 border-b-2 border-gray-100">
                                          <p className="text-gray-600">
                                            Persons{" "}
                                            <span className="font-semibold text-gray-800">
                                              {price.px_min} - {price.px_max}
                                            </span>
                                          </p>
                                          <p className="text-primary font-semibold">
                                            A$ {price.price}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                <></>
              )}
              <SectionHeading
                size={"2xl"}
                leading={"28px"}
                mt={6}
                text={"Accessibilities"}
              />
              <div className="flex gap-4 mt-4">
                <div className="md:w-[calc(100%-8px)] flex-none">
                  {accessibilities?.map((x, index) => (
                    <div
                      key={index}
                      className="flex text-xl mb-2 gap-2 items-center"
                    >
                      <BiSolidBadgeCheck
                        color={x.yes_no === "yes" ? "#63AB45" : "#1C231Fa2"}
                      />
                      <p className="font-medium text-sm">
                        {x.title} {x.yes_no ? x.yes_no.toUpperCase() : "NO"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <SectionHeading
                size={"2xl"}
                leading={"28px"}
                mt={6}
                text={"Health Restrictions"}
              />
              <p className="mt-4">{}</p>
              <div className="flex gap-4 mt-4">
                <div className="md:w-[calc(100%-8px)] flex-none">
                  {healthRestrictions?.map((x, index) => (
                    <div
                      key={index}
                      className="flex text-xl mb-2 gap-2 items-center"
                    >
                      <BiSolidBadgeCheck color="#63AB45" />
                      <p className="font-medium text-sm">{x.title}</p>
                    </div>
                  ))}
                </div>
              </div>
              {/* Questions Section */}
              <SectionHeading
                size={"2xl"}
                leading={"28px"}
                mt={6}
                text={"Questions"}
              />
              <div className="flex gap-4 mt-4">
                <div className="md:w-[calc(100%-8px)] flex-none">
                  {questions?.map((question, index) => (
                    <div
                      key={index}
                      className=" flex text-xl mb-2 gap-2 items-center"
                    >
                      <BiSolidBadgeCheck
                        color={
                          question.answer === "yes" ? "#63AB45" : "#1C231Fa2"
                        }
                      />
                      <p className="font-medium text-sm">
                        {question.question}{" "}
                        {question.answer ? question.answer.toUpperCase() : "NO"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              {/* <SectionHeading
                text={"Related Tours"}
                size={"2xl"}
                leading={"28px"}
                mt={6}
              />
              <div className="mt-6 text-left">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  arrows={false}
                  responsive={relatedTourResponsive}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  infinite
                  itemClass="related-card-style"
                >
                  {tourPlaceInfos.map((x, index) => (
                    <div key={index}>
                      <TourPlaceCard
                        cardImg={x.cardImg}
                        featured={x.featured}
                        off={x.off}
                        rating={x.rating}
                        title={x.title}
                        price={x.price}
                        place={x.place}
                        day={x.day}
                        people={x.people}
                        exploreBtnLink={x.exploreBtnLink}
                      />
                    </div>
                  ))}
                </Carousel>
              </div> */}
            </div>
            <div className="lg:w-[350px]">
              <BookingTour
                onClick={(e) => handleTourBooking(e, tourData)}
                location={tourData?.locationCountry}
                language={tourData?.languages || []}
                data={tourData || []}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourBookingDetails;
