import React from "react";
import Select from "react-select";

function MultiSelectLanguage({ languages, onChange }) {
  // Filter languages to include only those with "yes" in any of the fields
  const filteredLanguages = languages?.filter(
    (lang) =>
      lang.inperson === "yes" || lang.written === "yes" || lang.audio === "yes"
  );

  // console.log(filteredLanguages, languages);
  // Convert filtered languages to options format for react-select
  const options = filteredLanguages?.map((lang) => ({
    value: lang.id,
    label: lang.language,
  }));

  const handleChange = (selectedOptions) => {
    onChange(selectedOptions);
  };

  return (
    <Select
      isMulti
      name="languages"
      options={options}
      className="basic-multi-select w-[100%]"
      classNamePrefix="select"
      onChange={handleChange}
      placeholder="Select Languages"
    />
  );
}

export default MultiSelectLanguage;
