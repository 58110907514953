import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import LocationDropdown from "../Component/LocationDropdown";
import { HiMiniMinusSmall, HiMiniPlusSmall } from "react-icons/hi2";

const TransfersSearch = ({ resultPage }) => {
  const [startDate, setStartDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [passanger, setPassanger] = useState(4);
  return (
    <div
      className={`${resultPage ? "p-3 border rounded-xl" : "w-full"} bg-white`}
    >
      <div className={`${resultPage ? "lg:flex lg:gap-3" : ""}`}>
        <div className="flex w-full gap-2 pb-2 border-b">
          <img
            className="h-10 w-10 object-contain"
            src="/assets/icons/plane.png"
            alt="location"
          />
          <div className="w-full">
            <div className="flex w-full justify-between text-[#626262] items-center">
              <label htmlFor="Location">From</label>
            </div>
            <LocationDropdown apiKey="AIzaSyBKxlvXi_SANYC3WOH5FULVBbNFnbVKsCU" />
          </div>
        </div>
        <div
          className={`${
            resultPage ? "mt-4 lg:mt-0" : "mt-4"
          } flex w-full gap-2 pb-2 border-b`}
        >
          <img
            className="h-10 w-10 object-contain"
            src="/assets/icons/location1.png"
            alt="location"
          />
          <div className="w-full">
            <div className="flex w-full justify-between text-[#626262] items-center">
              <label htmlFor="Location">To</label>
            </div>
            <LocationDropdown apiKey="AIzaSyBKxlvXi_SANYC3WOH5FULVBbNFnbVKsCU" />
          </div>
        </div>
      </div>
      <div className={`${resultPage ? "lg:flex gap-3" : ""}`}>
        <div className="flex flex-col sm:flex-row items-end gap-4 mt-4">
          <div className="flex gap-2 pb-2 border-b w-full sm:w-1/2">
            <img
              className="h-10 w-10 object-contain"
              src="/assets/icons/calendar.png"
              alt="calendar"
            />
            <div className="w-full tour-date">
              <label className="text-[#626262] block mb-1" htmlFor="Date">
                Date
              </label>
              <DatePicker
                locale={"au"}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="DD/MM/YYYY"
                minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              />
            </div>
          </div>
          <div className="flex gap-2 pb-2 border-b w-full sm:w-1/2">
            <img
              className="h-10 w-10 object-contain"
              src="/assets/icons/time.png"
              alt="time"
            />
            <div className="w-full tour-date">
              <label className="text-[#626262] block mb-1" htmlFor="time">
                Arrival or Pickup Time
              </label>
              <DatePicker
                id="datePicker"
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeFormat="HH:mm aa"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-end gap-4 mt-4">
          <div className="flex gap-2 pb-2 border-b w-full sm:w-1/2">
            <img
              className="h-10 w-10 object-contain"
              src="/assets/icons/user.png"
              alt="user"
            />
            <div className="w-full tour-date">
              <label className="text-[#626262] block mb-1" htmlFor="passangers">
                Passengers
              </label>
              <div className="flex gap-2 items-center">
                <input
                  className="number-input w-[calc(100%-60px)] placeholder-black text-[18px] border-0 outline-none"
                  type="number"
                  placeholder="0"
                  value={passanger}
                  onChange={(e) => setPassanger(e.target.value)}
                />
                <div className="flex items-center gap-1">
                  <button
                    onClick={() =>
                      setPassanger(passanger > 0 ? parseInt(passanger) - 1 : 0)
                    }
                    className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
                  >
                    <HiMiniMinusSmall />
                  </button>
                  <button
                    onClick={() => setPassanger(parseInt(passanger) + 1)}
                    className="bg-[#FFA100] h-6 w-6 flex items-center justify-center text-white text-2xl"
                  >
                    <HiMiniPlusSmall />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Link
            to={"/transfer-search"}
            className=" bg-primary flex justify-center items-center h-12 text-center rounded-lg text-white w-full sm:w-1/2"
          >
            Search
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TransfersSearch;
