import React from "react";
import Tag from "../Component/Tag";
import SectionHeading from "../Component/SectionHeading";
import { FaTelegramPlane } from "react-icons/fa";

const sydneyContactDetails = [
  {
    icon: "/assets/contact/location.png",
    title: "Office Location",
    info: "PO BOX 83",
    info2: "Ingleburn, NSW, 2565",
  },
  {
    icon: "/assets/contact/email.png",
    title: "Email Address",
    info: "info@flymiles.com.au",
  },
  {
    icon: "/assets/contact/call.png",
    title: "Hotline",
    info: "+61 413 092 724",
  },
];

const SydneyContact = () => {
  return (
    <div className="container mx-auto">
      <div className="flex justify-center items-center flex-wrap gap-12 mt-9 mx-3">
        {sydneyContactDetails.map((x, index) => (
          <div
            className="flex items-center justify-center flex-col bg-[#F9F9F7] h-[200px] w-[250px]"
            key={index}
          >
            <img className="h-14 w-14 object-contain" src={x.icon} alt="icon" />
            <p className="mt-4 mb-2 text-sm">{x.title}</p>
            <p>{x.info}</p>
            {x.info2 ? <p>{x.info2}</p> : null}
          </div>
        ))}
      </div>
      <div className="mt-12 mx-3">
        <Tag center text={"Get In Touch"} />
        <SectionHeading mt={6} text={"Send Us Message"} center />
        <div className="flex items-center justify-center mx-auto gap-3 mt-6 max-w-[768px]">
            <input type="text" className="border px-4 py-2 w-full bg-[#F9F9F7]" placeholder="Name" />
            <input type="tel" className="border px-4 py-2 w-full bg-[#F9F9F7]" placeholder="Phone Number" />
        </div>
        <div className="flex items-center justify-center mx-auto gap-3 mt-6 max-w-[768px]">
            <input type="email" className="border px-4 py-2 w-full bg-[#F9F9F7]" placeholder="Email Address" />
            <input type="text" className="border px-4 py-2 w-full bg-[#F9F9F7]" placeholder="Country" />
        </div>
        <div className="flex items-center justify-center flex-col mx-auto gap-3 mt-6 max-w-[768px]">
            <textarea name="text area" className="border w-full bg-[#f9f9f7]" id="" rows="5"></textarea>
            <button className="flex items-center border rounded-full bg-primary text-white font-medium">
                <span className="px-4 py-3">Send Us Message</span>
                <span className="w-9 h-9 bg-white text-secondary flex justify-center items-center rounded-full me-1"><FaTelegramPlane color={"#63AB45"} /></span>
            </button>
        </div>
      </div>
    </div>
  );
};

export default SydneyContact;
