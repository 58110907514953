import React from "react";
import HeroBanner from "../Sections/HeroBanner";
import { useState } from "react";
import { MdChevronRight } from "react-icons/md";
import SectionHeading from "../Component/SectionHeading";
import { Link } from "react-router-dom";

const navigationTitle = [
  "Privacy Policy",
  "Information Collection And Use",
  "Log Data",
  "XML/API",
  "Communications",
  "Cookies",
  "Security",
  "Changes To This Privacy Policy",
  "Contact Us",
];

const PrivacyPolicy = () => {
  const [selectedNavigation, setSelectedNavigation] = useState(
    navigationTitle[0]
  );
  const handleNavigation = (x) => {
    setSelectedNavigation(x);
  };
  return (
    <>
      <HeroBanner
        text={"Privacy Policy"}
        imgUrl={"/assets/contact/hero-banner.png"}
      />
      <div className="container mx-auto">
        <div className="mx-3 lg:flex gap-6 py-12">
          <div className="lg:w-[320px] border h-max py-6 mb-6 lg:mb-0">
            <h1 className="font-semibold text-xl px-4 pb-3">Navigation</h1>
            {navigationTitle.map((x, index) => (
              <button
                onClick={() => handleNavigation(x)}
                key={index}
                className={`relative flex items-center font-medium gap-1 py-3 mx-3 ${
                  selectedNavigation === x ? "text-primary" : ""
                }`}
              >
                <span
                  className={`h-full w-1 absolute -left-3 ${
                    selectedNavigation === x ? " bg-primary" : ""
                  }`}
                ></span>
                <MdChevronRight />
                {x}
              </button>
            ))}
          </div>
          <div className="lg:w-[calc(100%-344px)]">
            {/* Privacy Policy */}
            {selectedNavigation === "Privacy Policy" && (
              <>
                <SectionHeading text={"Privacy Policy"} />
                <p className="mt-6">
                  <strong>Last updated: 02nd Feburary 2021.</strong>
                  <br />
                  <br />
                  <strong>FLY MILES PTY. LTD.</strong> ("us", "we", or "our")
                  operates https://www.flymiles.com.au (the "Site"). This page
                  informs you of our policies regarding the collection, use and
                  disclosure of Personal Information we receive from users of
                  the Site. We use your Personal Information only for providing
                  and improving the service you have requested for and used for
                  that purpose alone. By using the Site, you agree to the
                  collection and use of information in accordance with this
                  policy all information provided by you is your sole
                  responsibility and FLY MILES PTY. LTD. accepts no
                  responsibility for any incorrect information provided in
                  respect to the hiring made through this facility. It is not
                  given to a third party except where the purpose relates to
                  fulfilling the obligations of your hiring. Your personal
                  information may be retained for use in future bookings. If at
                  any time you believe the information is incorrect, you may
                  contact us to have it corrected.
                </p>
              </>
            )}
            {/* Information Collection */}
            {selectedNavigation === "Information Collection And Use" && (
              <>
                <SectionHeading text={"Information Collection And Use"} />
                <p className="mt-6">
                  While using our Site, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you. Personally, identifiable
                  information may include, but is not limited to your name
                  ("Personal Information").
                </p>
              </>
            )}
            {/* Log Data */}
            {selectedNavigation === "Log Data" && (
              <>
                <SectionHeading text={"Log Data"} />
                <p className="mt-6">
                  Like many site operators, we collect information that your
                  browser sends whenever you visit our Site ("Log Data").
                  <br />
                  <br />
                  This Log Data may include information such as your computer's
                  Internet Protocol ("IP") address, browser type, browser
                  version, the pages of our Site that you visit, the time and
                  date of your visit, the time spent on those pages and other
                  statistics.
                  <br />
                  <br />
                  In addition, we may use third party services such as Google
                  Analytics that collect, monitor and analyze this …
                </p>
              </>
            )}
            {/* XML */}
            {selectedNavigation === "XML/API" && (
              <>
                <SectionHeading text={"XML/API"} />
              </>
            )}
            {/* Communication */}
            {selectedNavigation === "Communications" && (
              <>
                <SectionHeading text={"Communication"} />
                <p className="mt-6">
                  We may use your Personal Information to contact you with
                  regards to newsletters, marketing or promotional materials and
                  other information like may be obliged to surrender your
                  personal information to regulatory authorities and law
                  enforcement officials in accordance with the applicable law.
                  Personal information may also be disclosed to identify,
                  contact or bring legal action against anyone damaging,
                  injuring or interfering with our rights or property.
                </p>
              </>
            )}
            {/* Cookies */}
            {selectedNavigation === "Cookies" && (
              <>
                <SectionHeading text={"Cookies"} />
                <p className="mt-6">
                  Cookies are files with small amount of data, which may include
                  an anonymous unique identifier. Cookies are sent to your
                  browser from a web site and stored on your computer's hard
                  drive.
                  <br />
                  <br />
                  Like many sites, we use "cookies" to collect information. You
                  can instruct your browser to refuse all cookies or to indicate
                  when a cookie is being sent. However, if you do not accept
                  cookies, you may not be able to use some portions of our Site.
                </p>
              </>
            )}
            {/* Security */}
            {selectedNavigation === "Security" && (
              <>
                <SectionHeading text={"Security"} />
                <p className="mt-6">
                  The security of your Personal Information is important to us,
                  but remember that no method of transmission over the Internet,
                  or method of electronic storage, is 100% secure. While we
                  strive to use commercially acceptable means to protect your
                  Personal Information, we cannot guarantee its absolute
                  security.
                </p>
              </>
            )}
            {/* Changes To This Privacy Policy */}
            {selectedNavigation === "Changes To This Privacy Policy" && (
              <>
                <SectionHeading text={"Changes To This Privacy Policy"} />
                <p className="mt-6">
                  This Privacy Policy is effective as of 02nd Feburary 2021 and
                  will remain in effect except with respect to any changes in
                  its provisions in the future, which will be in effect
                  immediately after being posted on this page.
                  <br />
                  <br />
                  We reserve the right to update or change our Privacy Policy at
                  any time and you should check this Privacy Policy
                  periodically. Your continued use of the Service after we post
                  any modifications to the Privacy Policy on this page will
                  constitute your acknowledgment of the modifications and your
                  consent to abide and be bound by the modified Privacy Policy.
                  <br />
                  <br />
                  If we make any material changes to this Privacy Policy, we
                  will notify you either through the email address you have
                  provided us, or by placing a prominent notice on our website.
                </p>
              </>
            )}
            {/* Contact Us */}
            {selectedNavigation === "Contact Us" && (
              <>
                <SectionHeading text={"Contact Us"} />
                <p className="mt-6">
                If you have any questions about this Privacy Policy, please <Link className="text-primary" to={"/contact-us"}>contact us.</Link>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
